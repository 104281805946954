import React from "react";

const PrimaryButtonBig = ({ title, onClick }) => {
  return (
    <button
      className="manaskopas-main-sub5-btn5 focus-red"
      type="button"
      onClick={onClick}
    >
      <img
        alt="alt"
        className="manaskopas-main-sub5-icon"
        src="/assets/img/add.svg"
      />
      <p className="manaskopas-main-sub5-font">{title}</p>
    </button>
  );
};

export default PrimaryButtonBig;
