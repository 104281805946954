import PropTypes from "prop-types";

export const SearchButton = ({ hasText, onClick, className }) => {
  if (hasText) {
    return (
      <svg
        className={`svg-default ${className ?? ""}`}
        style={{ cursor: "pointer" }}
        onClick={() => onClick?.()}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <line x1="4" x2="15" y1="4" y2="15"></line>
        <line x1="15" x2="4" y1="4" y2="15"></line>
      </svg>
    );
  }
  return (
    <svg
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#5a5a5b"
    >
      <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
    </svg>
  );
};
SearchButton.propTypes = {
  hasText: PropTypes.bool,
  onClick: PropTypes.func,
};
