import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../context";
import FooterImageComponent from "./atoms/footerimage";
import { Trans } from "react-i18next";
import { auth } from "../classes/auth";

export const DEFAULT_MAP_URL =
  window.location.href.includes("test") || process.env.NODE_ENV === "development"
    ? "https://map-test.lvceli.lv/?lang="
    : "https://map.transportdata.gov.lv/?lang=";

const Footer = () => {
  const { language, contrast } = useContext(StateContext);
  const { handleModalOpen } = useContext(StateContext);
  const navigate = useNavigate();

  const footerSource =
    contrast !== "contrast_blue"
      ? `/assets/img/npp-minislide-${language}1.svg`
      : `/assets/img/npp-minislide-colored-${language}1.svg`;

  return (
    <div>
      <div className="footer-center-image-container">
        <img
          alt="alt"
          className={`footer-up-container-image ${
            contrast !== "contrast_blue" ? "footer-up-container-image-colored" : ""
          }`}
          src={footerSource}
        />
      </div>
      <FooterImageComponent />
      <div className="footer-middle-container">
        <div className="footer-middle-container-menus">
          <div className="footer-middle-container-menus-cats">
            <img alt="alt" className="footer-middle-container-menus-icon" src="/assets/img/location.png" />
            <div className="footer-middle-container-menu">
              <div
                className="footer-middle-container-menus-link focus-red"
                tabIndex={0}
                onClick={() => {
                  navigate("/catalog");
                  // we still need to scroll to top here because footer exists on the same page
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    navigate("/catalog");
                    // we still need to scroll to top here because footer exists on the same page
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }
                }}
              >
                <img
                  alt="alt"
                  className="footer-middle-container-menus-link-icon"
                  src="/assets/img/up1.svg"
                />
                <Trans i18nKey="dataCatalog" />
              </div>
              <a
                className="footer-middle-container-menus-link focus-red"
                href={DEFAULT_MAP_URL + language}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="alt"
                  className="footer-middle-container-menus-link-icon"
                  src="/assets/img/up1.svg"
                />
                <Trans i18nKey="interactiveMap" />
              </a>
              {!auth.isAuthorized() && (
                <div
                  className="footer-middle-container-menus-link focus-red"
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleModalOpen("auth");
                    }
                  }}
                >
                  <div onClick={() => handleModalOpen("auth")}>
                    <img
                      alt="alt"
                      className="footer-middle-container-menus-link-icon"
                      src="/assets/img/up1.svg"
                    />
                    <Trans i18nKey="authorize" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="footer-middle-container-social">
            <div className="footer-middle-container-social-sites">
              {/* <a href="https://facebook.com" target="_blank">
                <img alt="alt" src="/assets/img/facebook.svg" />
              </a>
              <a href="https://twitter.com" target="_blank">
                <img className="footer-social-img"  alt="alt" src="/assets/img/twitter.svg" />
              </a> */}
            </div>
            <div className="footer-middle-container-social-mail">
              <div className="footer-middle-container-social-mail-info">
                <span>npp@lvceli.lv</span>
                <br />
                <a href={(language === "en" ? "/en" : "/lv") + "/kontakti"} className="focus-red">
                  <Trans i18nKey="contacts" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-end">
        <p className="footer-end-font">
          <Trans i18nKey="copyRight" />
        </p>
      </div>
    </div>
  );
};

export default Footer;
