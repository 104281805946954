import { auth, fetch } from "../../../classes/auth";

const endpoint = process.env.REACT_APP_CMS_API || "/";

export const getDownloadLinks = async (file, nid) => {
  const token = await auth.getToken('id_token');

  const formData = new FormData();
  formData.append("id", nid);

  try {
    const response = await fetch(endpoint + `api/v1/get/file/download/${file.target_id}`, {
      method: "POST",
      body: formData,
      headers: {
        token: token,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return { ...responseData, target_id: file.target_id };
    } else {
      throw new Error(responseData.error || "Something went wrong.");
    }
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while calling the API.");
  }
};
