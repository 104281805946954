import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteCms } from "../classes/cmsApi";
import PrimaryButton from "../common/atoms/primarybutton";
import Loader from "../common/loader";
import { StateContext } from "../context";
import { ErrorMessage } from "../pages/manaskopas/components/errormessage";
import "./index.css";
import ModalComponent from "./modal";
import { t } from "i18next";

const DeleteModal = ({
  id,
  trigger,
  modalTtile,
  title = t("areYouSureYouWantToUnsubscribe"),
  subtitle = t("youCanSubscribeUsing"),
  link = t("usingCatalog"),
  buttonTitle,
  navLink = "/catalog",
  onButtonPress,
  svg=true,
  refetch,
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleModalClose, modalSettings, language, user } = useContext(StateContext);
  const { props } = modalSettings;
  buttonTitle =   buttonTitle ? buttonTitle : t("delete");
  const handleButtonPress = async () => {
    setLoading(true);
    await deleteCms("api/v1/remove/subscription/" + (props?.id ?? ""))
      .then(() => {
        setError(false);
        if (refetch) {
          refetch();
        }
        handleModalClose(id ?? "delete");
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  return (
    <ModalComponent
      id={id ?? "delete"}
      trigger={trigger}
      small
      title={modalTtile ?? "Dzēst datu kopu"}
      className={"modal-stack delete-modal"}
    >
      <div className="edit-profile-modal-content">
        <div className="ielades-dialog-content-sub1">
          <img alt="alt" className="ielades-dialog-content-sub1-icon1" src="/assets/img/check.svg" />
          <p className="ielades-dialog-content-sub1-font2">{title}</p>
        </div>

        <span className="ielades-dialog-content-sub2-font1">
          {subtitle}
          &nbsp;
          <span
            className="ielades-dialog-content-sub2-font1-link focus-red"
            tabIndex={0} 
            onClick={() => navigate(navLink)}
            onKeyDown={(e)=>{
              if(e.key === "Enter"){
                navigate(navLink);
              }
            }}
          >
            {link}

            {svg && (
              <img
                alt="alt"
                className="manaskopas-main-tb1-tr2-td1-warm1"
                style={{ marginLeft: 10 }}
                src="/assets/img/link.svg"
              />
            )}
          </span>
        </span>
        <div style={{ marginTop: 34 }} />
        {error && <ErrorMessage onClose={() => setError(false)} />}
        {loading ? (
          <Loader />
        ) : (
          <PrimaryButton
            style={{
              height: 64,
              textAlign: "center",
              justifyContent: "center",
            }}
            title={buttonTitle}
            hideSvg
            onClick={onButtonPress ? onButtonPress : handleButtonPress}
          />
        )}
      </div>
    </ModalComponent>
  );
};

export default DeleteModal;
