import { Trans } from "react-i18next";

const response_key_map = {
    "max_file_request_reached": {
        "title": <Trans i18nKey="universalPopupMaxFileRequestReachedTitle" />,
        "body": <Trans i18nKey="universalPopupMaxFileRequestReachedDescription" />
    },
    "no_files_found": {
        "title": <Trans i18nKey="emptyDataSet" />,
        "body": <Trans i18nKey="siprDkNotFound" />
    }
};

export default response_key_map;