export const countries = [
  {value: 'AF', label: 'Afganistāna'},
  {value: 'AL', label: 'Albānija'},
  {value: 'DZ', label: 'Alžīrija'},
  {value: 'US', label: 'Amerikas Savienotās Valstis'},
  {value: 'AD', label: 'Andora'},
  {value: 'AI', label: 'Angilja'},
  {value: 'AO', label: 'Angola'},
  {value: 'AQ', label: 'Antarktika'},
  {value: 'AG', label: 'Antigva un Barbuda'},
  {value: 'GB', label: 'Apvienotā Karaliste'},
  {value: 'AE', label: 'Apvienotie Arābu Emirāti'},
  {value: 'AR', label: 'Argentīna'},
  {value: 'AM', label: 'Armēnija'},
  {value: 'AW', label: 'Aruba'},
  {value: 'UM', label: 'ASV Mazās Aizjūras salas'},
  {value: 'AS', label: 'ASV Samoa'},
  {value: 'VI', label: 'ASV Virdžīnas'},
  {value: 'AU', label: 'Austrālija'},
  {value: 'AT', label: 'Austrija'},
  {value: 'TL', label: 'Austrumtimora'},
  {value: 'AZ', label: 'Azerbaidžāna'},
  {value: 'BS', label: 'Bahamu salas'},
  {value: 'BH', label: 'Bahreina'},
  {value: 'BY', label: 'Baltkrievija'},
  {value: 'BD', label: 'Bangladeša'},
  {value: 'BB', label: 'Barbadosa'},
  {value: 'BZ', label: 'Beliza'},
  {value: 'BE', label: 'Beļģija'},
  {value: 'BJ', label: 'Benina'},
  {value: 'BM', label: 'Bermudu salas'},
  {value: 'BO', label: 'Bolīvija'},
  {value: 'BA', label: 'Bosnija un Hercegovina'},
  {value: 'BW', label: 'Botsvāna'},
  {value: 'BR', label: 'Brazīlija'},
  {value: 'VG', label: 'Britu Virdžīnas'},
  {value: 'BN', label: 'Bruneja'},
  {value: 'BG', label: 'Bulgārija'},
  {value: 'BF', label: 'Burkinafaso'},
  {value: 'BI', label: 'Burundija'},
  {value: 'BT', label: 'Butāna'},
  {value: 'BV', label: 'Buvē sala'},
  {value: 'CF', label: 'Centrālāfrikas Republika'},
  {value: 'TD', label: 'Čada'},
  {value: 'CZ', label: 'Čehija'},
  {value: 'CL', label: 'Čīle'},
  {value: 'DK', label: 'Dānija'},
  {value: 'ZA', label: 'Dienvidāfrikas Republika'},
  {value: 'GS', label: 'Dienviddžordžija un Dienvidsendviču salas'},
  {value: 'KR', label: 'Dienvidkoreja'},
  {value: 'SS', label: 'Dienvidsudāna'},
  {value: 'DM', label: 'Dominika'},
  {value: 'DO', label: 'Dominikāna'},
  {value: 'JE', label: 'Džērsija'},
  {value: 'DJ', label: 'Džibutija'},
  {value: 'EG', label: 'Ēģipte'},
  {value: 'EC', label: 'Ekvadora'},
  {value: 'GQ', label: 'Ekvatoriālā Gvineja'},
  {value: 'ER', label: 'Eritreja'},
  {value: 'ET', label: 'Etiopija'},
  {value: 'FO', label: 'Fēru salas'},
  {value: 'FJ', label: 'Fidži'},
  {value: 'PH', label: 'Filipīnas'},
  {value: 'FK', label: 'Folklenda salas'},
  {value: 'FR', label: 'Francija'},
  {value: 'TF', label: 'Francijas Dienvidjūru teritorija'},
  {value: 'GF', label: 'Francijas Gviāna'},
  {value: 'PF', label: 'Francijas Polinēzija'},
  {value: 'GA', label: 'Gabona'},
  {value: 'GY', label: 'Gajāna'},
  {value: 'GM', label: 'Gambija'},
  {value: 'GH', label: 'Gana'},
  {value: 'GG', label: 'Gērnsija'},
  {value: 'GI', label: 'Gibraltārs'},
  {value: 'GD', label: 'Grenāda'},
  {value: 'GL', label: 'Grenlande'},
  {value: 'GR', label: 'Grieķija'},
  {value: 'GE', label: 'Gruzija'},
  {value: 'GU', label: 'Guama'},
  {value: 'GP', label: 'Gvadelupa'},
  {value: 'GT', label: 'Gvatemala'},
  {value: 'GN', label: 'Gvineja'},
  {value: 'GW', label: 'Gvineja-Bisava'},
  {value: 'HT', label: 'Haiti'},
  {value: 'HM', label: 'Hērda sala un Makdonalda salas'},
  {value: 'HN', label: 'Hondurasa'},
  {value: 'HR', label: 'Horvātija'},
  {value: 'EE', label: 'Igaunija'},
  {value: 'IN', label: 'Indija'},
  {value: 'IO', label: 'Indijas okeāna Britu teritorija'},
  {value: 'ID', label: 'Indonēzija'},
  {value: 'IQ', label: 'Irāka'},
  {value: 'IR', label: 'Irāna'},
  {value: 'IE', label: 'Īrija'},
  {value: 'IS', label: 'Islande'},
  {value: 'IT', label: 'Itālija'},
  {value: 'IL', label: 'Izraēla'},
  {value: 'JM', label: 'Jamaika'},
  {value: 'JP', label: 'Japāna'},
  {value: 'NC', label: 'Jaunkaledonija'},
  {value: 'NZ', label: 'Jaunzēlande'},
  {value: 'YE', label: 'Jemena'},
  {value: 'JO', label: 'Jordānija'},
  {value: 'CV', label: 'Kaboverde'},
  {value: 'KY', label: 'Kaimanu salas'},
  {value: 'KH', label: 'Kambodža'},
  {value: 'CM', label: 'Kamerūna'},
  {value: 'CA', label: 'Kanāda'},
  {value: 'QA', label: 'Katara'},
  {value: 'KZ', label: 'Kazahstāna'},
  {value: 'KE', label: 'Kenija'},
  {value: 'CY', label: 'Kipra'},
  {value: 'CW', label: 'Kirasao'},
  {value: 'KG', label: 'Kirgizstāna'},
  {value: 'KI', label: 'Kiribati'},
  {value: 'CC', label: 'Kokosu (Kīlinga) salas'},
  {value: 'CO', label: 'Kolumbija'},
  {value: 'KM', label: 'Komoru salas'},
  {value: 'CG', label: 'Kongo (Brazavila)'},
  {value: 'CD', label: 'Kongo (Kinšasa)'},
  {value: 'CR', label: 'Kostarika'},
  {value: 'CI', label: 'Kotdivuāra'},
  {value: 'RU', label: 'Krievija'},
  {value: 'CU', label: 'Kuba'},
  {value: 'CK', label: 'Kuka salas'},
  {value: 'KW', label: 'Kuveita'},
  {value: 'CN', label: 'Ķīna'},
  {value: 'HK', label: 'Ķīnas īpašās pārvaldes apgabals Honkonga'},
  {value: 'MO', label: 'ĶTR īpašais administratīvais reģions Makao'},
  {value: 'LA', label: 'Laosa'},
  {value: 'LV', label: 'Latvija'},
  {value: 'LS', label: 'Lesoto'},
  {value: 'LB', label: 'Libāna'},
  {value: 'LR', label: 'Libērija'},
  {value: 'LY', label: 'Lībija'},
  {value: 'LT', label: 'Lietuva'},
  {value: 'LI', label: 'Lihtenšteina'},
  {value: 'LU', label: 'Luksemburga'},
  {value: 'MG', label: 'Madagaskara'},
  {value: 'YT', label: 'Majota'},
  {value: 'MY', label: 'Malaizija'},
  {value: 'MW', label: 'Malāvija'},
  {value: 'MV', label: 'Maldīvija'},
  {value: 'ML', label: 'Mali'},
  {value: 'MT', label: 'Malta'},
  {value: 'MA', label: 'Maroka'},
  {value: 'MH', label: 'Māršala salas'},
  {value: 'MQ', label: 'Martinika'},
  {value: 'MU', label: 'Maurīcija'},
  {value: 'MR', label: 'Mauritānija'},
  {value: 'MX', label: 'Meksika'},
  {value: 'ME', label: 'Melnkalne'},
  {value: 'IM', label: 'Menas sala'},
  {value: 'FM', label: 'Mikronēzija'},
  {value: 'MM', label: 'Mjanma (Birma)'},
  {value: 'MD', label: 'Moldova'},
  {value: 'MC', label: 'Monako'},
  {value: 'MN', label: 'Mongolija'},
  {value: 'MS', label: 'Montserrata'},
  {value: 'MZ', label: 'Mozambika'},
  {value: 'NA', label: 'Namībija'},
  {value: 'NR', label: 'Nauru'},
  {value: 'NP', label: 'Nepāla'},
  {value: 'NL', label: 'Nīderlande'},
  {value: 'BQ', label: 'Nīderlandes Karību salas'},
  {value: 'NE', label: 'Nigēra'},
  {value: 'NG', label: 'Nigērija'},
  {value: 'NI', label: 'Nikaragva'},
  {value: 'NU', label: 'Niue'},
  {value: 'NF', label: 'Norfolkas sala'},
  {value: 'NO', label: 'Norvēģija'},
  {value: 'AX', label: 'Olandes salas'},
  {value: 'OM', label: 'Omāna'},
  {value: 'PK', label: 'Pakistāna'},
  {value: 'PW', label: 'Palau'},
  {value: 'PS', label: 'Palestīnas teritorijas'},
  {value: 'PA', label: 'Panama'},
  {value: 'PG', label: 'Papua-Jaungvineja'},
  {value: 'PY', label: 'Paragvaja'},
  {value: 'PE', label: 'Peru'},
  {value: 'PN', label: 'Pitkērnas salas'},
  {value: 'PL', label: 'Polija'},
  {value: 'PT', label: 'Portugāle'},
  {value: 'PR', label: 'Puertoriko'},
  {value: 'RE', label: 'Reinjona'},
  {value: 'EH', label: 'Rietumsahāra'},
  {value: 'RW', label: 'Ruanda'},
  {value: 'RO', label: 'Rumānija'},
  {value: 'SV', label: 'Salvadora'},
  {value: 'WS', label: 'Samoa'},
  {value: 'SM', label: 'Sanmarīno'},
  {value: 'ST', label: 'Santome un Prinsipi'},
  {value: 'SA', label: 'Saūda Arābija'},
  {value: 'SC', label: 'Seišelu salas'},
  {value: 'BL', label: 'Senbartelmī'},
  {value: 'SN', label: 'Senegāla'},
  {value: 'MF', label: 'Senmartēna'},
  {value: 'PM', label: 'Senpjēra un Mikelona'},
  {value: 'KN', label: 'Sentkitsa un Nevisa'},
  {value: 'LC', label: 'Sentlūsija'},
  {value: 'VC', label: 'Sentvinsenta un Grenadīnas'},
  {value: 'RS', label: 'Serbija'},
  {value: 'SG', label: 'Singapūra'},
  {value: 'SX', label: 'Sintmārtena'},
  {value: 'SY', label: 'Sīrija'},
  {value: 'SL', label: 'Sjerraleone'},
  {value: 'SK', label: 'Slovākija'},
  {value: 'SI', label: 'Slovēnija'},
  {value: 'SO', label: 'Somālija'},
  {value: 'FI', label: 'Somija'},
  {value: 'ES', label: 'Spānija'},
  {value: 'SD', label: 'Sudāna'},
  {value: 'SR', label: 'Surinama'},
  {value: 'SH', label: 'Sv.Helēnas sala'},
  {value: 'SJ', label: 'Svalbāra un Jana Majena sala'},
  {value: 'SZ', label: 'Svatini'},
  {value: 'LK', label: 'Šrilanka'},
  {value: 'CH', label: 'Šveice'},
  {value: 'TJ', label: 'Tadžikistāna'},
  {value: 'TW', label: 'Taivāna'},
  {value: 'TH', label: 'Taizeme'},
  {value: 'TZ', label: 'Tanzānija'},
  {value: 'TC', label: 'Tērksas un Kaikosas salas'},
  {value: 'TG', label: 'Togo'},
  {value: 'TK', label: 'Tokelau'},
  {value: 'TO', label: 'Tonga'},
  {value: 'TT', label: 'Trinidāda un Tobāgo'},
  {value: 'TN', label: 'Tunisija'},
  {value: 'TR', label: 'Turcija'},
  {value: 'TM', label: 'Turkmenistāna'},
  {value: 'TV', label: 'Tuvalu'},
  {value: 'UG', label: 'Uganda'},
  {value: 'UA', label: 'Ukraina'},
  {value: 'HU', label: 'Ungārija'},
  {value: 'UY', label: 'Urugvaja'},
  {value: 'UZ', label: 'Uzbekistāna'},
  {value: 'DE', label: 'Vācija'},
  {value: 'VU', label: 'Vanuatu'},
  {value: 'VA', label: 'Vatikāns'},
  {value: 'VE', label: 'Venecuēla'},
  {value: 'VN', label: 'Vjetnama'},
  {value: 'WF', label: 'Volisa un Futunas salas'},
  {value: 'SB', label: 'Zālamana salas'},
  {value: 'ZM', label: 'Zambija'},
  {value: 'KP', label: 'Ziemeļkoreja'},
  {value: 'MK', label: 'Ziemeļmaķedonija'},
  {value: 'MP', label: 'Ziemeļu Marianas salas'},
  {value: 'CX', label: 'Ziemsvētku sala'},
  {value: 'ZW', label: 'Zimbabve'},
  {value: 'SE', label: 'Zviedrija'},
]
