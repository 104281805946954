import { useEffect,useState, useContext } from "react";
import { Banner } from "../common";
import { getCms } from "../classes/cmsApi";
import { useTranslation } from 'react-i18next';
import { StateContext } from "../context";

const BUJ = () => {
  const [sectionData, setSectionData] = useState(null);
  const { language } = useContext(StateContext);
  const { t } = useTranslation();
  
  const bannerData = {
    title: t("FAQ"),
    path1: t("workspaceStart"),
    path2: t("AboutThePlatform"),
    path3: t("QA"),
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const textSectionData = await getCms(`api/v1/content/${language}/buj`);
        setSectionData(textSectionData);
      } catch (error) {
        // Handle thine error here
      }
    };
  
    fetchData();
  }, []);


  return (
    <>
      <Banner bannerData={bannerData} />
      <div className="content"></div>
      <div className="content-main">
        <div className="content-main-left">
          <div className="content-main-left-width">
            {sectionData.body[0].value}
          </div>
        </div>
        <div className="content-main-right">
          <div>
            <img alt="alt" src="/assets/img/content.png" />
          </div>
        </div>
      </div>
    </>
  );
};

export default BUJ;
