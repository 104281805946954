import { unstable_useBlocker as useBlocker } from "react-router-dom";

function Prompt(props) {
  const block = props.when;

  useBlocker(() => {
    if (block) {
      if (window.confirm("Vai tiešām vēlaties atcelt ievadītās izmaiņas?")) {
        props.removeTempData();
        return false;
      } else {
        props.saveTempDataSet();
        return true;
      }
    }
    return false;
  });

  return <div key={block} />;
}

export default Prompt;
