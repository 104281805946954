import { Banner } from "../../common";
import { useTranslation } from "react-i18next";
import ReportsPageTable from "./ReportsPageTable";

const ReportsPage = () => {
  const { t } = useTranslation();

  const bannerData = {
    title: t("workspaceCharts"),
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("menuProfileFeatures"),
    path2Url: "/dashboard",
    path3: t("workspaceCharts"),
    img: {
      href: "/assets/img/earth.svg",
      className: "reports-title-icon",
    },
  };

  return (
    <>
      <Banner bannerData={bannerData} shouldCenterImg={true} />

      <div className="manaskopas-main" style={{ marginBottom: "5em" }}>
        <ReportsPageTable />
      </div>
    </>
  );
};

export default ReportsPage;
