import { createContext, useCallback, useEffect, useRef, useState } from "react";
import i18n from "i18next";
import { usr } from "../classes/user";
import { auth } from "../classes/auth";
import { useLocalStorage } from "../helpers/customHooks";

const StateContext = createContext();

const initialModalSettings = {
  modalOpen: false,
  activeModal: "",
};

const languageList = {
  lv: { label: "Latviski" },
  en: { label: "English" },
};

const fontSizeList = {
  100: { label: "100%" },
  125: { label: "150%" },
  150: { label: "200%" },
};

const contrastList = {
  contrast_blue: { color: "#00ccf5" },
  contrast_blackwhite: { label: "Black" },
  contrast_blackyellow: { label: "Black/Yellow" },
  contrast_yellow: { color: "#fde900" },
};

const recheckUpdatedSiteRules = async () => {
  if (!usr.sub) {
    return false;
  }
  await usr.refresh();
  return usr.agreedToTermsOfService !== "true";
};

const useUpdatedSiteRules = () => {
  const [updatedSiteRules, setUpdatedSiteRules] = useState(false);

  const checkUpdatedSiteRules = useCallback(async () => {
    const updated = await recheckUpdatedSiteRules();

    setUpdatedSiteRules(updated);
  }, []);

  useEffect(() => {
    checkUpdatedSiteRules();
  }, [checkUpdatedSiteRules]);

  return [updatedSiteRules, checkUpdatedSiteRules, setUpdatedSiteRules];
};

const StateProvider = ({ children }) => {
  const [modalSettings, setModalSettings] = useState(initialModalSettings);
  const [navbarOpened, setNavBarOpened] = useState(false);
  const [language, setLanguage] = useLocalStorage("language", "lv");
  const [fontSize, setFontSize] = useLocalStorage("fontSize", "100");
  const [contrast, setContrast] = useLocalStorage("contrast", "contrast_blue");
  const [highlightedPane, setHighlightedPane] = useState(null);
  const [updatedSiteRules, checkUpdatedSiteRules, setUpdatedSiteRules] = useUpdatedSiteRules();
  const [hasOpenedAuthBox, setHasOpenedAuthBox] = useState(false);

  const currentModal = useRef(null);

  // Add a function to toggle the reading mode
  const toggleHighlightedPane = (highlightedPane) => {
    setHighlightedPane(highlightedPane);
  };

  function handleModalOpen(id, props) {
    currentModal.current = id;

    document.documentElement.style.overflow = "hidden";
    document.body.scroll = "no";
    setModalSettings({
      modalOpen: true,
      activeModal: id,
      props,
    });
  }

  useEffect(() => {
    // set the handleModalOpen function to the auth class
    auth.setHandleModalOpen(handleModalOpen);
  }, []);

  const handleModalClose = (modalToClose = "") => {
    if (modalToClose !== currentModal.current && modalToClose) return;

    document.documentElement.style.overflow = "auto";
    document.body.scroll = "yes";

    setModalSettings({
      modalOpen: false,
      activeModal: "",
    });
  };

  const changeLanguageInUrl = (lang, pathName = "") => {
    if (!pathName) return;

    const availableLanguages = Object.keys(languageList);
    const doesCurrentPathContainLanguage = availableLanguages.some((l) => {
      return pathName.startsWith(`/${l}`);
    });
    // in case url doesnt contain language, we dont need to change it
    if (!doesCurrentPathContainLanguage) return;
    if (availableLanguages.includes(lang)) {
      const newPathName = pathName.replace(/^\/[a-z]{2}/, `/${lang}`);
      window.history.pushState({}, "", newPathName);
    }
  };

  const changeLanguage = (lng, pathName) => {
    if (lng === language) {
      // console.warn("Language is already set to", lng);
      return;
    }
    changeLanguageInUrl(lng, pathName);
    setLanguage(lng);
    i18n.changeLanguage(lng);
  };

  return (
    <StateContext.Provider
      value={{
        navbarOpened,
        setNavBarOpened,
        handleModalOpen,
        handleModalClose,
        modalSettings,
        language,
        setLanguage,
        changeLanguage,
        languageDefinition: languageList,
        fontDefinition: fontSizeList,
        contrastDefinition: contrastList,
        fontSize,
        setFontSize,
        contrast,
        setContrast,
        toggleHighlightedPane,
        highlightedPane,
        updatedSiteRules,
        checkUpdatedSiteRules,
        setUpdatedSiteRules,
        hasOpenedAuthBox,
        setHasOpenedAuthBox,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export { StateContext, StateProvider };
