import DeleteModal from "../../../modals/deletemodal";
import { AbonetasKopasTableRow } from "../../manaskopas/components/tables/AbonetasKopasTable";
import { handleDownload } from "../helpers";
import { deleteCms } from "../../../classes/cmsApi";
import { Trans } from 'react-i18next';
import { t } from "i18next";

const Table = ({ data, headers, callback, onSort }) => {
  return (
    <div className="manaskopas-main" style={{ marginBottom: 30 }}>
      <div style={{ overflowX: "auto", borderRadius: 4 }}>
        <table className="manaskopas-main-tb1" style={{tableLayout: "fixed"}}>
          <TableHeader headers={headers} onSort={onSort} />
          {data?.map((item, index) => (
            <TableRow callback={callback} item={item} key={item?.term_id} />
          ))}
        </table>
      </div>
      <DeleteModal svg />
    </div>
  );
};

const TableRow = ({ item, callback }) => {

  const handleConfirm = async () => {
    try {
      const result = window.confirm("Vai tiešām vēlaties dzēst šo apliecinājumu?");
      if (result) {
        await deleteCms(`api/delete/confirmation/term`, {}, item?.term_id);
        callback();
      }
    } catch (error) {
      if (error.message) {
        if (error.message === "Declaration is in use") alert(t("confirmationInUse"))
        else alert(error.message)
      };
      console.error("Error deleting confirmation term:", error);
    }
  };

  return (
    <AbonetasKopasTableRow
      onTitleClick={() => handleDownload(item?.file_path, item?.term_title)}
      colProps={{
        col1: {
          svg: "download",
          svgClass: "manaskopas-td-icon-download",
          td1ContentClass: "apliecinajumi-td-contnet",
          td1Class: "apliecinajumi-td-1",
        },
        col2: {
          td2Class: "apliecinajumi-td-2",
        },
      }}
      item={{
        ...item,
        title: item.term_title,
      }}
      status
      col2={
        <td>
          <div className="manaskopas-main-tb1-tr2-td3">
            <div
              className="manaskopas-main-tb1-tr2-td3-left manaskopas-margin-x-2em"
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span style={{ minWidth: "6em" }}>{item?.creation_time?.slice(0, 10)}</span>
              <div 
                onClick={handleConfirm} 
                className="manaskopas-main-tb1-tr2-td3-right-part1 manaskopas-main-tb1-tr2-td3-right-part1-delete"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleConfirm();
                }
              }
              >
                <img
                  alt="alt"
                  className="manaskopas-main-tb1-tr2-td3-right-part2-sub2 manaskopas-main-tb1-tr2-td3-right-part2-sub2-delete"
                  src="/assets/img/bin.svg"
                />
                <span className="manaskopas-main-tb1-tr2-td3-right-part2-font2"><Trans i18nKey="delete" /></span>
              </div>
            </div>
          </div>
        </td>
      }
      col3={<></>}
    />
  );
};

export const TableHeader = ({ headers, onSort }) => {
  return (
    <tr className="manaskopas-main-tb1-tr1">
      {headers.map((title, index) =>
        index === 0 ? (
          <th key={title} className="manaskopas-main-tb1-tr1-th1">
            <span className="manaskopas-main-tb1-tr1-th1-font1" style={{ marginRight: "0.5em" }}>{headers[0]}</span>
            <img
              alt="alt"
              className="manaskopas-main-tb1-tr1-th1-sort"
              src="/assets/img/sort.svg"
              onClick={onSort}
            />
          </th>
        ) : (
          <th key={title}>
            <span className="manaskopas-main-tb1-tr1-th1-font1">{title}</span>
          </th>
        )
      )}
    </tr>
  );
};

export default Table;
