import React, { useContext } from "react";
import ModalComponent from "./modal";
import { StateContext } from "../context";
import { t } from "i18next";

const TokenExpiredModal = ({ trigger }) => {
  const { handleModalClose, handleModalOpen } = useContext(StateContext);

  const handleClose = () => {
    handleModalClose("tokensexpired");
};

  const handleAuthentificateAgain = async () => {
    const redirectTo = window.location.pathname;
    handleModalOpen("auth", { redirectTo });
  };

  const Content = () => {
    return (
      <div className="token-expired-modal-content">
        <span>{t("closeTheBrowserBegin")}</span>
        <span
          tabIndex={0}
          className="token-expired-modal-link focus-red"
          onClick={handleAuthentificateAgain}
          onKeyDown={(e)=>{
            if(e.key === "Enter"){
              handleAuthentificateAgain();
            }
          }}
        >
          {t("closetheBrowserEndLink")}
        </span>
      </div>
    );
  };

  return (
    <ModalComponent
      id="tokensexpired"
      className="token-expired-modal"
      trigger={trigger}
      title={t("sessionTimeOver")}
      onClose={handleClose}
    >
      <Content />
    </ModalComponent>
  );
};

export default TokenExpiredModal;
