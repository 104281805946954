import { fetch } from "./auth";

const host = process.env.REACT_APP_CMS_API || '/';

export const getCms = (model = "", language = "lv", extra = {}, page = null, size = null, headers = {}) => {
  if (size) extra.size = size;
  if (page) extra.page = page;
  return new Promise((resolve, reject) => {
    fetch(`${host}${language}/${model}${Object.keys(extra).length ? "?" : ""}` + new URLSearchParams(extra), {
      method: `GET`,
      headers: {
        crossDomain: true,
        Accept: `application/json`,
        ...headers,
      },
    })
      .then(async (response) => {
        if (response.ok && response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          reject({ error: "Notika kļūda" });
        }
      })
      .catch((err) => {
        console.error("CMS api error: ", err);
        reject(err);
      });
  });
};

export const postCms = (model = "", body = {}, id = null, headers = {}, expectFile = false) => {
  return new Promise((resolve, reject) => {
    const isFormData = body instanceof FormData;
    const jsonHeaders = {
      Accept: `application/json`,
      "Content-Type": `application/json`,
    };
    fetch(`${host}${model}/${id ?? ""}`, {
      method: `POST`,
      headers: {
        crossDomain: true,
        ...(isFormData || expectFile ? {} : jsonHeaders),
        ...headers,
      },
      body: isFormData ? body : JSON.stringify(body ?? {}),
    })
      .then(async (response) => {
        if (response.ok && response.status === 200) {
          if (expectFile) {
            const blob = await response.blob();
            resolve(blob);
          }
          const data = await response.json();
          resolve(data);
        } else {
          const errorData = await response.json();
          reject({ error: errorData, status: response.status });
        }
      })
      .catch((err) => {
        console.error("CMS api error: ", err);
        reject({ error: "Notika kļūda" });
      });
  });
};

export const deleteCms = (model = "", body = {}, id = null, headers = {}) => {
  return new Promise((resolve, reject) => {
    fetch(`${host}${model}/${id ?? ""}`, {
      method: `DELETE`,
      headers: {
        crossDomain: true,
        Accept: `application/json`,
        "Content-Type": `application/json`,
        ...headers,
      },
      body: JSON.stringify(body ?? {}),
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.ok && response.status === 200) {
          resolve(data);
        } else {
          reject({ error: "Notika kļūda", message: data.error });
        }
      })
      .catch((err) => {
        console.error("CMS api error: ", err);
        reject({ error: "Notika kļūda" });
      });
  });
};
