import { useState } from "react";
import "./index.css";
import PrimaryButton from "../common/atoms/primarybutton";
import ModalComponent from "./modal";
import CheckBox from "../common/checkbox";
import { useTranslation } from 'react-i18next';
import { postCms } from "../classes/cmsApi";


const RateModal = ({ trigger, nid }) => {
  const { t } = useTranslation();
  const rateFormOpions = [t("popupMessageHighRating"), "4", "3", "2", t("popupMessageLowRating")];
  const [active, setActive] = useState(5);
  const [sent, setSent] = useState(false);
  const handleCheckboxes = (id) => setActive(id);

  const handleSendClick = async () => {
    try {
      const result = await postCms(`api/v1/add/rating`, { score: active }, nid);

      if (result?.error) {
        console.error(result?.error);
        return;
      }
      setSent(true);
    } catch (error) {
      console.error(error?.error ?? "An error occurred");
    }
  };

  return (
    <ModalComponent
      id="rate"
      trigger={trigger}
      small
      title={t("popupMessageRate")}
      className={"modal-stack rate-modal"}
    >
      <div className="edit-profile-modal-content">
        <div style={{ marginTop: 34 }} />
        <span className="modal-dialog-content-font2">
          {sent ? t("popupMessageThanksForRateDataset") : t("popupMessagePleaseRateDataset")}
        </span>
        <div style={{ marginTop: 34 }} />

        {!sent && (
          <>
            {rateFormOpions.map((item, index) => (
              <CheckBox
                key={index}
                text={item}
                checked={rateFormOpions.length - index === active}
                round
                onClick={() => handleCheckboxes(rateFormOpions.length - index)}
                style={{ marginBottom: "0.5em" }}
              />
            ))}
            <div style={{ marginTop: 34 }}>
              <PrimaryButton
                style={{
                  height: 64,
                  width: 134,
                  textAlign: "center",
                  justifyContent: "center",
                  padding: 0,
                }}
                title={t("popupMessageSend")}
                hideSvg
                onClick={handleSendClick}
              />
            </div>
          </>
        )}
      </div>
    </ModalComponent>
  );
};

export default RateModal;
