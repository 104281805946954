export const handleDownload = async (fileUrl, fileName) => {
  try {
    const response = await fetch(fileUrl);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};
