import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getCms } from "../../../classes/cmsApi";
import { AuthBox, Banner } from "../../../common";
import PrimaryButtonBig from "../../../common/atoms/primarybuttonbig";
import Loader from "../../../common/loader";
import { ErrorMessage, ReloadPageButton } from "../components/errormessage";
import { AbonetasKopasTable } from "../components/tables/AbonetasKopasTable";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const getBannerData = (t) => {
  
  const bannerData = {
    title: t("workspaceSubscribedData"),
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("menuProfileFeaturesCaps"),
    path2Url: "/dashboard",
    path3: t("workspaceSubscribedDataCaps"),
    img: {
      href: "/assets/img/wi.svg",
      className: "manaskopas-title-left-stitle-icon",
    },
  };
  return bannerData;
};


const fetchData = async () => await getCms("api/v1/get/subscribed/list");

export const useSubbedData = () => {
  const { data, isLoading, isError, error, refetch } = useQuery(
    [`subbedData`],
    async () => {
      return await fetchData();
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return { data, isLoading, isError, error, refetch };
};

const AbonetasKopas = () => {
  const { t } = useTranslation();
  const { data, isLoading, isError, error, refetch } = useSubbedData();
  const navigate = useNavigate();
  const bannerData = getBannerData(t);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Banner bannerData={bannerData} children={<AuthBox />} />
      <div className="manaskopas-main">
        {isError && (
          <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <ErrorMessage text={error.error ?? "Notika kļūda"} />
            <ReloadPageButton />
          </div>
        )}
        <AbonetasKopasTable data={data} refetch={refetch} />
        {isLoading && <Loader />}
        <div
          className="manaskopas-main-mqtt"
        >
          {t("mqttServer")}: mqtt://stream.transportdata.gov.lv:1883
        </div>
        <PrimaryButtonBig title={t("subscribeToDS")} onClick={() => navigate("/catalog")} />
      </div>
    </>
  );
};

export default AbonetasKopas;
