const LabelTag = ({ text, setText, onRemove, forceNotHovered, isInteractable = true }) => {

  return (
    <label
      className="label focus-red"
    >
      <p className={`font ${!isInteractable ? "label-catalog-font" : ""}`}>{text}</p>
      {isInteractable && (
        <div className="focus-red">
          <img
            alt="remove input"
            onClick={onRemove}
            className="focus-red label-tag-close"
            src={"/assets/img/close.svg"}
            style={{ verticalAlign: "baseline" }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onRemove();
              }
            }}
          />
        </div>
      )}
    </label>
  );
};

export default LabelTag;
