import { useNavigate } from "react-router-dom";

const SubNavbarData = ({ children, setNavBarOpened }) => {
  const navigate = useNavigate();
  if (!children) return null;

  return (
    <>
      {children.map((item, key) => {
        return (
          <button
            className={item.field_menu_icon ? "navbar-menus-tab-imgs-item focus-red" : `${item.field_menu_icon} focus-red`}
            key={key}
            tabIndex={0}
            onClick={() => {
              // contact's should open in new tab ?
              if (item.link__title.startsWith("http://") || item.link__title.startsWith("https://")) //  || item.link__title === "/lv/kontakti" 
                window.open(item.link__title, "_blank");
              else navigate(item.link__title);
              setNavBarOpened(false);
            }}
            style={{ textDecoration: "none" }}
            onBlur={(a) => {
              // if no other element is focused, close the subnavbar
              if (!a.relatedTarget) {
                let items = document.getElementsByClassName("navbar-menus-tab-imgs");
                let item = items[0];
                item.classList.remove("visible");

                let items2 = document.getElementsByClassName("navbar-menus-tab-content");
                let item2 = items2[0];
                item2.classList.remove("visible");
              }
            }}
          >
            {item.field_menu_icon && (
              <img alt="" className={item.field_menu_icon_class} src={item.field_menu_icon} />
            )}
            {item.title}
          </button>
        );
      })}
    </>
  );
};

export default SubNavbarData;
