import { useLayoutEffect, useRef, useCallback } from "react";

// This only needs to exist because the native smooth scroll is not reliable if you call it multiple times in quick succession.
// If clicked twice the second click will scroll for the remaining time of the first click.
const useSuperDuperReliableSmoothScroll = (dependencyArray, id = null) => {
  const animationFrameRef = useRef(null);
  const startTimeRef = useRef(performance.now());
  const initial = useRef(true);

  const animateScroll = useCallback(
    (time) => {
      const duration = 300;
      const elapsedTime = time - startTimeRef.current;
      const progress = Math.min(elapsedTime / duration, 1);

      if (id && !initial.current && document.querySelector(id)) {
        document.querySelector(id).scrollIntoView({ block: "start", inline: "start", behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }

      if (progress < 1) {
        return (animationFrameRef.current = requestAnimationFrame(animateScroll));
      }
      initial.current = false;
    },
    [id]
  );

  const handleScroll = useCallback(() => {
    startTimeRef.current = performance.now();
    animationFrameRef.current = requestAnimationFrame(animateScroll);
  }, [animateScroll]);

  useLayoutEffect(() => {
    handleScroll();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [handleScroll, dependencyArray]);
};

export default useSuperDuperReliableSmoothScroll;
