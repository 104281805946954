import React from "react";

const PrimaryButton = ({
  title = "Turpināt",
  svg = "next",
  style,
  imgClass,
  hideSvg,
  onClick,
  disabled,
  svgStyle,
  id,
}) => {
  return (
    <button
      id={id}
      disabled={disabled}
      onClick={onClick}
      className={`register-info-right-next-profile-button${disabled ? `-disabled` : ``} focus-red`}
      style={{ ...style, ...(disabled ? { filter: `grayscale(1)`, cursor: `not-allowed` } : {}) }}
    >
      {!hideSvg && (
        <img alt="alt" src={`/assets/img/${svg}.svg`} className={imgClass} style={{ ...svgStyle }} />
      )}
      {title}
    </button>
  );
};

export default PrimaryButton;
