import { auth, fetch } from "./auth";

export const BACKEND_URL = '/auth';
export const SSO_URL = '';

export const get = (url, extra = []) => {
  return new Promise((r, f) => {
    fetch(
      `${url}${extra.length ? `?` : ``}${extra
        .map((p) => `${p.key}=${p.value}`)
        .join(`&`)}`,
      {
        headers: {
          Accept: `application/json`,
          // Authorization: auth.getToken(),
        },
      }
    )
      .then(async (data) => {
        const response = { ok: data.ok, status: data.status };
        if (data.status === 401) {
          auth.logout();
        }
        const _data = await data.json();
        r({ ...response, data: _data });
      })
      .then((data) => r(data))
      .catch((err) => f(err));
  });
};

export const post = async (url, body, headers = {}) => {
  return new Promise((r, f) => {
    fetch(url, {
      method: `POST`,
      headers: {
        Accept: `application/json`,
        "Content-Type": `application/json`,
        // Authorization: auth.getToken(),
        ...headers,
      },
      body: body,
    })
      .then(async (data) => {
        const response = { ok: data.ok, status: data.status };
        if (data.status === 401) {
          auth.logout();
        }
        const _data = await data.json();
        r({ ...response, data: _data });
      })
      .then((data) => r(data))
      .catch((err) => f(err));
  });
};

export const destroy = async (url) => {
  return new Promise((r, f) => {
    fetch(url, {
      method: `DELETE`,
      headers: {
        Accept: `application/json`,
        // Authorization: auth.getToken(),
      },
    })
      .then(async (data) => {
        const response = { ok: data.ok, status: data.status };
        if (data.status === 401) {
          auth.logout();
        }
        const _data = await data.json();
        r({ ...response, data: _data });
      })
      .then((data) => r(data))
      .catch((err) => f(err));
  });
};
