import { DataBox, Dropdown, MultipleDropdown } from "..";
import { useTranslation } from "react-i18next";
import Field from "../../../../common/atoms/textfield";

export const xmlSyntax = { key: "xml", value: "XML" };

export const modelIsDatex = (modelTerm, datexModelTerms) => {
  if (!modelTerm) return false;

  for (const key in datexModelTerms) {
    if (datexModelTerms[key] === modelTerm) return true;
  }
  return false;
}

export const syntaxIsXML = (syntax) =>  {
  if (!syntax) return false;
  if (Array.isArray(syntax)) {
    for (const key of syntax) {
      if (key === "xml") return true;
    }
  }
  return syntax === "xml";
}

const DataFormatBox = ({
  defval,
  isNew,
  register,
  getValues = () => ({}),
  setFiles,
  getFieldState,
  schemas,
  errors,
  setValue,
  dataFormatModel,
  dataModelSyntax,
  selectedSyntax,
  setSelectedSyntax,
  resetSelectedSyntax,
  setResetSelectedSyntax,
  removeValidationErrors,
  publicationTypes,
  checkboxState,
  datexModelTerms,
  selectedModel,
  setSelectedModel
}) => {
  const { t } = useTranslation();
  
  const children =
    defval.data_set_statuss === "publish"
      ? [
          <>
            <Field
              title={t("datasetAddDataModel")}
              text={dataFormatModel?.find((v) => v.key === defval.format_model)?.value ?? "-"}
            />
            <Field
              title={t("datasetAddDataModelSchema")}
              text={(schemas && schemas[dataFormatModel]?.find((v) => v.key === defval?.data_format_schema)?.value) ?? "-"}
            />
            <Field
              title={t("datasetAddDataModelSyntax")}
              text={
                defval?.field_data_format_syntax
                  ? Array.isArray(defval?.field_data_format_syntax)
                    ? defval?.field_data_format_syntax
                        .map((v) => dataModelSyntax.find((syntax) => syntax.key === v)?.value)
                        .join(", ")
                    : dataModelSyntax.find((v) => v.key === defval?.field_data_format_syntax)?.value
                  : "-"
              }
            />
            {modelIsDatex(defval?.format_model, datexModelTerms) && (
              <Field
                title={t("datasetAddDatexPublicationClass")}
                text={publicationTypes?.find((v) => v.key === defval?.datex_publication_type)?.value ?? "-"}
              />
            )}
          </>,
        ]
      : [
          <div className="input-group">
            <Dropdown
              fieldName="format_model"
              placeholder={t("datasetAddDataModel")}
              defval={defval?.format_model}
              register={register}
              errors={errors}
              rules={{
                required: { message: "fieldIsRequired", value: true },
              }}
              onChange={(selectedValue) => {
                if (datexModelTerms.includes(selectedValue)) {
                  setSelectedSyntax([xmlSyntax.key]);
                  setValue("field_data_format_syntax", xmlSyntax.key);
                  setFiles([]);
                }
                setResetSelectedSyntax((current) => !current);
                setSelectedModel(selectedValue);
                removeValidationErrors();
              }}
              onBeforeChange={
                isNew
                  ? undefined
                  : (value) => {
                      // confirm, that attached files should be removed, if syntax is changed
                      if (datexModelTerms.includes(value) && !syntaxIsXML(selectedSyntax)) {
                        return !window.confirm(t("popupMessageChangeFormat"));
                      }
                    }
              }
              options={dataFormatModel} // TODO leave keys, values, remove isDatex
              setValue={setValue}
              getValues={getValues}
              getFieldState={getFieldState}
            />
            {(defval?.distribution_mode === "file" || checkboxState.check1) && (
              <MultipleDropdown
                fieldName="field_data_format_syntax"
                placeholder={t("datasetAddDataModelSyntax")}
                defval={
                  defval?.field_data_format_syntax
                    ? Array.isArray(defval?.field_data_format_syntax)
                      ? defval?.field_data_format_syntax
                      : [defval?.field_data_format_syntax]
                    : []
                }
                register={register}
                errors={errors}
                rules={{
                  required: { message: "fieldIsRequired", value: true },
                }}
                onChange={() => {
                  setSelectedSyntax(getValues("field_data_format_syntax"));
                }}
                options={datexModelTerms.includes(selectedModel) ? [xmlSyntax] : dataModelSyntax}
                setValue={setValue}
                resetOptions={resetSelectedSyntax}
                getValues={getValues}
                getFieldState={getFieldState}
              />
            )}
            {(defval?.distribution_mode === "api" || checkboxState.check2) && (
              <MultipleDropdown
                fieldName="field_data_format_syntax"
                placeholder={t("datasetAddDataModelSyntax")}
                defval={
                  defval?.field_data_format_syntax
                    ? Array.isArray(defval?.field_data_format_syntax)
                      ? defval?.field_data_format_syntax
                      : [defval?.field_data_format_syntax]
                    : []
                }
                register={register}
                errors={errors}
                rules={{
                  required: { message: "fieldIsRequired", value: true },
                }}
                options={datexModelTerms.includes(selectedModel) ? [xmlSyntax] : dataModelSyntax}
                // options={dataModelSyntax}
                setValue={setValue}
                resetOptions={resetSelectedSyntax}
                getValues={getValues}
                getFieldState={getFieldState}
              />
            )}
            {syntaxIsXML(selectedSyntax) && (
              <Dropdown
                fieldName="data_format_schema"
                placeholder={t("datasetAddDataModelSchema")}
                defval={defval?.data_format_schema}
                register={register}
                errors={errors}
                rules={{
                  required: {
                    value: syntaxIsXML(selectedSyntax) && !!selectedModel,
                    message: t("fieldIsRequired"),
                  },
                  validate: {
                    schemaSelected: (value) => {
                      if (syntaxIsXML(selectedSyntax)) {
                        return value ? true : t("fieldIsRequired");
                      }
                      return true; // Not required for non-XML syntax
                    }
                  }

                }}
                onChange={() => removeValidationErrors()}
                options={schemas[selectedModel]}
                setValue={setValue}
                getValues={getValues}
                getFieldState={getFieldState}
              />
            )}
            {modelIsDatex(selectedModel, datexModelTerms) && (
              <Dropdown
                fieldName="datex_publication_type"
                placeholder={t("datasetAddDatexPublicationClass")}
                defval={defval?.datex_publication_type}
                register={register}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                      message: t("fieldIsRequired"),
                  }
                }}

                resetOptions={resetSelectedSyntax}
                options={publicationTypes}
                setValue={setValue}
                getValues={getValues}
                getFieldState={getFieldState}
              />
            )}
          </div>,
        ];

  return <DataBox label={t("datasetAddDataFormat")} children={children} />;
};

export default DataFormatBox;
