export const licenceTypes = [
  { key: "royalty_free", value: "royalty free" },
  { key: "other_contractual_agreement", value: "Other contractual agreement" },
  { key: "creative_commons_cc_zero", value: "Creative Commons CC Zero" },
  { key: "creative_commons_cc_by", value: "Creative Commons CC BY" },
  { key: "creative_commons_cc_by_sa", value: "Creative Commons CC BY SA" },
  { key: "creative_commons_cc_by_nd", value: "Creative Commons CC BY ND" },
  { key: "creative_commons_cc_by_nc", value: "Creative Commons CC BY NC" },
  { key: "creative_commons_cc_nc_sa", value: "Creative Commons CC NC SA" },
  { key: "creative_commons_cc_by_nc_nd", value: "Creative Commons CC BY NC ND" },
  {
    key: "software_license_of_apache_software_foundation",
    value: "Software license of Apache Software Foundation",
  },
  { key: "gnu_free_documentation_license", value: "GNU Free Documentation License" },
  { key: "mozilla_public_license", value: "Mozilla Public License" },
  {
    key: "open_data_commons_attribution_license_odcby_10",
    value: "Open Data Commons Attribution License (ODC-BY 1.0)",
  },
  {
    key: "open_data_commons_open_database_license_odbl",
    value: "Open Data Commons Open Database License (ODbL)",
  },
  {
    key: "open_data_commons_public_domain_dedications_and_license_odc_pddl",
    value: "Open Data Commons Public Domain Dedications and License (ODC PDDL)",
  },
  { key: "public_domain_mark_10_pdm", value: "Public Domain Mark 1.0 (PDM)" },
  { key: "not_relevant", value: "Not relevant" },
];
