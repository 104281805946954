import { useCallback, useEffect, useState } from "react";
import PrimaryButton from "../../../common/atoms/primarybutton";
import CheckBox from "../../../common/checkbox";
import "../index.css";
import OverAllData from "./overalldata";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { getCms } from "../../../classes/cmsApi";
import { Link } from "react-router-dom";

const AddBox = ({ step, onClick, extraData, checkboxState, setCheckBoxState }) => {
  const [activeHeader, setActiveHeader] = useState(0);
  const handleCheckboxes = useCallback(
    (key) => {
      if (step === 0) {
        const updatedCheckboxState = {
          check1: false,
          check2: false,
        };

        updatedCheckboxState[key] = true;

        setCheckBoxState(updatedCheckboxState);
      }
    },
    [step, setCheckBoxState]
  );

  const handleClick = (header) => {
    setActiveHeader(header);
  };

  return (
    <div className="box-kopas">
      <div className="card-content-detail-tab">
        <div className="card-content-detail-tab-tabbutton">
          <button
            onClick={() => handleClick(0)}
            className={`card-content-detail-tab-tabbutton ${activeHeader === 0 ? "active" : ""}`}
            style={{ borderRadius: 0 }}
          >
            <Trans i18nKey="datasetAddInfoAboutDataset" />
          </button>
          {/* {!extraData?.isNew && (
            <button
              onClick={() => handleClick(1)}
              className={`card-content-detail-tab-tabbutton ${activeHeader === 1 ? "active" : ""}`}
              style={{ borderRadius: 0 }}
            >
              <Trans i18nKey="datasetChangeHistory" />
            </button>
          )} */}
        </div>
      </div>

      <div className="content-add-kopa">
        {activeHeader === 0 ? (
          <>
            {extraData?.isNew && (
              <StepOne
                step={step}
                onClick={onClick}
                handleCheckboxes={handleCheckboxes}
                checkboxState={checkboxState}
                setCheckBoxState={setCheckBoxState}
                extraData={extraData}
              />
            )}
            {step === 1 && (
              <>
                {!extraData?.isNew && <h1 className="h1"> {extraData?.formData?.dataset_name} </h1>}
                <OverAllData
                  extraData={extraData}
                  declarations={extraData?.declarations?.active}
                  checkboxState={checkboxState}
                />
              </>
            )}
          </>
        ) : (
          <>
            <h1>
              <Trans i18nKey="datasetAddChangeHistory" />
            </h1>
            <p>
              Loerm ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl quis tincidunt
              ultricies, nunc nisl dignissim diam, sed lacinia nisl velit sit amet nisl. Donec
            </p>
          </>
        )}
      </div>
    </div>
  );
};

const StepOne = ({ step, onClick, checkboxState, handleCheckboxes, extraData }) => {
  const [blockMessage, setBlockMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getCms("api/v1/get/owner/confirmation/list");
        let endObject = {
          active: [],
          inactive: [],
        };

        if (result.error) {
          // setBlockMessage('There was an error fetching the data.');
          return;
        }

        if (result.length) {
          result?.forEach((entry) => {
            const { value: statusValue } = entry.status[0];
            entry.status = statusValue;

            const { value: creationTimeValue } = entry.creation_time[0];
            entry.creation_time = creationTimeValue;

            if (entry.status === "1") {
              endObject.active.push(entry);
            } else {
              endObject.inactive.push(entry);
            }
          });
        }

        if (endObject.active.length === 0) {
          setBlockMessage("No active items found.");
        }

        // Do something with the fetched data
      } catch (error) {
        console.error("There was a problem fetching the data:", error);
        // setBlockMessage('There was a problem fetching the data.');
      }
    };

    fetchData();
  }, []);

  return (
    <div className="content-title-add-kopa" style={{ opacity: step === 0 ? 1 : 0.5 }}>
      <span className="content-title-h1">
        <Trans i18nKey="datasetAddPublicationType" />
      </span>
      <div className="content-subtitle-add-kopa">
        <Trans i18nKey="datasetAddChooseDataType" />
      </div>

      <CheckBox
        disabled={step !== 0}
        text={t("datasetAddByAddingFiles")}
        onClick={() => handleCheckboxes("check1")}
        checked={checkboxState?.check1}
      />
      <CheckBox
        disabled={step !== 0}
        text={t("datasetAddByAPI")}
        checked={checkboxState?.check2}
        onClick={() => handleCheckboxes("check2")}
      />

      {step === 0 && (
        <>
          <div className="register-info-right-rules-note" style={{ margin: "0 0 0 0" }}>
            <Trans i18nKey="datasetAddCannotChange" />
          </div>
          {blockMessage ? (
            <div
              className="register-info-right-rules-note"
              style={{ display: "block", backgroundColor: "#9e313a", margin: "0 0 0 0" }}
            >
              <Trans i18nKey="datasetNoDeclarations" />
              <Link to="/apliecinajumi">
                <Trans i18nKey="datasetNoDeclarationsLink" />
              </Link>
            </div>
          ) : null}

          <PrimaryButton
            disabled={!Object.values(checkboxState).some((val) => val === true)}
            onClick={onClick}
            style={{
              width: "fit-content",
              height: "3em",
              fontSize: 24,
            }}
            svgStyle={{ height: 18, width: 27 }}
            title={t("registrationContinue")}
            hideSvg={step === 1}
          />
        </>
      )}
    </div>
  );
};

export default AddBox;
