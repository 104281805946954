import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { StateContext, StateProvider } from "./frontend/context/statecontext";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';  // Assuming this is where your i18n object is defined
import { usr } from "./frontend/classes/user"; // do not remove user initialization
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";
import RoutesWrapper from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./global-styles.css";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return null;
}

const useMatomo = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Matomo tracking
      try {
      const _paq = (window._paq = window._paq || []);
      const u = "/";

      _paq.push(["trackPageView"]);
      _paq.push(["enableLinkTracking"]);
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", "1"]);

      const d = document;
      const g = d.createElement("script");
      const s = d.getElementsByTagName("script")[0];

      g.type = "text/javascript";
      g.async = true;
      g.src = u + "matomo.js";
      s.parentNode.insertBefore(g, s);

      // Matomo tag manager
      const _mtm = (window._mtm = window._mtm || []);
      _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });

      // const g2 = d.createElement("script");
      // g2.type = "text/javascript";
      // g2.async = true;
      // g2.src = "/js/container_oJDxcgig.js";
      // s.parentNode.insertBefore(g2, s);
      } catch (e) {
        console.error("Matomo error", e);
      }
    }
  }, []);
};

const queryClient = new QueryClient();

const App = () => {
  useMatomo();
  return (
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <StateProvider>
          <WrappedRoutes />
        </StateProvider>
      </QueryClientProvider>
    </I18nextProvider>
  );
};

const WrappedRoutes = () => {
  
  const {fontSize, contrast} = useContext(StateContext);
  // document.getElementById("body").className = "high-contrast";
  // document.getElementById("body").style.setProperty("--foreground-color", "yellow");
  // document.getElementById("body").style.setProperty("--background-color", "black");
  
  if (contrast !== "contrast_blue") {
    document.getElementById("body").className = "high-contrast";
    if (contrast === "contrast_yellow") {
      document.getElementById("body").style.setProperty("--foreground-color","rgb(255, 255, 0)");
      document.getElementById("body").style.setProperty("--foreground-color-transparent","rgba(255, 255, 0, 0.1)");
      document.getElementById("body").style.setProperty("--background-color", "black");
      document.getElementById("body").style.setProperty("--image-grayscale", "0%");
      document.getElementById("body").style.setProperty("--image-invert", "0%");
      document.getElementById("body").style.setProperty("--yellow-sepia", "300%");
      document.getElementById("body").style.setProperty("--yellow-saturation", "2000%");

      document.getElementById("body").style.setProperty("--c_input_border", "rgb(255, 255, 0)");
      document.getElementById("body").style.setProperty("--c_input_option_focused", "rgba(255, 255, 0, 0.2)");
      document.getElementById("body").style.setProperty("--c_input_option_selected", "rgba(255, 255, 0, 0.5)");
      document.getElementById("body").style.setProperty("--c_input_option_focused_selected", "rgba(255, 255, 0, 0.4)");
    } 
    if (contrast === "contrast_blackwhite") {
      document.getElementById("body").style.setProperty("--image-grayscale", "100%");
      document.getElementById("body").style.setProperty("--image-invert", "0%");
      document.getElementById("body").style.setProperty("--foreground-color", "white");
      document.getElementById("body").style.setProperty("--foreground-color-transparent","rgba(255, 255, 255, 0.1)");
      document.getElementById("body").style.setProperty("--background-color", "black");
      document.getElementById("body").style.setProperty("--yellow-sepia", "0%");
      document.getElementById("body").style.setProperty("--yellow-saturation", "0%");

      document.getElementById("body").style.setProperty("--c_input_border", "white");
      document.getElementById("body").style.setProperty("--c_input_option_focused", "rgba(255, 255, 255, 0.2)");
      document.getElementById("body").style.setProperty("--c_input_option_selected", "rgba(255, 255, 255, 0.5)");
      document.getElementById("body").style.setProperty("--c_input_option_focused_selected", "rgba(255, 255, 255, 0.4)");
    }
    if (contrast === "contrast_blackyellow") {
      document.getElementById("body").style.setProperty("--image-grayscale", "100%");
      document.getElementById("body").style.setProperty("--image-invert", "100%");
      document.getElementById("body").style.setProperty("--foreground-color", "black");
      document.getElementById("body").style.setProperty("--foreground-color-transparent","rgba(0, 0, 0, 0.4)");
      document.getElementById("body").style.setProperty("--background-color", "yellow");
      document.getElementById("body").style.setProperty("--yellow-sepia", "300%");
      document.getElementById("body").style.setProperty("--yellow-saturation", "2000%");

      document.getElementById("body").style.setProperty("--c_input_border", "black");
      document.getElementById("body").style.setProperty("--c_input_option_focused", "rgba(255, 255, 255, 0.6)");
      document.getElementById("body").style.setProperty("--c_input_option_selected", "rgba(255, 255, 255, 0.9)");
      document.getElementById("body").style.setProperty("--c_input_option_focused_selected", "rgba(255, 255, 255, 0.7)");
    }
  } else {
    document.getElementById("body").className = "not-high-contrast";

    document.getElementById("body").removeAttribute("style");
  }

  const fontSizeMultiplier = 1 + (Number(fontSize) - 100) / 100;

  return (
    <div
      className="font-size-adjust"
      style={{
        "--font-size-multiplier": fontSizeMultiplier,
        "--contrast-color": contrast,
        transition: fontSizeMultiplier !== 1 ? "0.3s ease-in-out" : "none",
      }}
    >
      <RoutesList />
    </div>
  );
};

const RoutesList = () => (
  <GoogleReCaptchaProvider
    reCaptchaKey="6LccbTsmAAAAAJDKQRam7iYep5ATLEbVRPoZWSTP"
    language="lv"
    // useRecaptchaNet="[optional_boolean_value]"
    // useEnterprise="[optional_boolean_value]"
    // scriptProps={{
    //   async: false, // optional, default to false,
    //   defer: false, // optional, default to false
    //   appendTo: 'head', // optional, default to "head", can be "head" or "body",
    //   nonce: undefined // optional, default undefined
    // }}
    container={{
      // optional to render inside custom element
      // element: "[required_id_or_htmlelement]",
      parameters: {
        // badge: '[inline|bottomright|bottomleft]', // optional, default undefined
        theme: "dark", // optional, default undefined
      },
    }}
  >
    <RoutesWrapper />
  </GoogleReCaptchaProvider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
