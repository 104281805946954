import { createBrowserRouter, Outlet, RouterProvider, useLocation } from "react-router-dom";
import {
  AbonetasKopas,
  AddKopa,
  Apliecinajums,
  BUJ,
  Card,
  Catalog,
  ConfirmedEmailPage,
  Dashboard,
  DatuSniedzeji,
  Error,
  Help,
  Klusti,
  Logout,
  ManasKopas,
  Messages,
  PasswordCreate,
  PasswordReset,
  PasswordNew,
  Profile,
  Register,
  RedirectToLv,
  ReportsPage,
  Search,
  Textual,
  Title,
} from "../frontend/pages";
import { Layout } from "../frontend/common";
import GenericDrupalPage from "../frontend/pages/genericDrupalPage";
import { usr as user } from "../frontend/classes/user";
import { SiteRulesUpdatedModal } from "../frontend/modals/siteRulesUpdatedModal";
import TokenExpiredModal from "../frontend/modals/tokenexpiredmodal";
import { useContext, useEffect } from "react";
import { StateContext } from "../frontend/context";

const AppLayout = ({ bannerData }) => {
  const { language, changeLanguage, languageDefinition } = useContext(StateContext);
  const location = useLocation();

  // change language on first load from url
  useEffect(() => {
    // grabbing lang from url
    const pathName = location.pathname;
    const lang = pathName.split("/")[1];
    const availableLanguages = Object.keys(languageDefinition);
    if (availableLanguages.includes(lang)) {
      changeLanguage(lang, pathName);
    } else {
      changeLanguage(language, pathName); // default or last used language
    }
  }, [changeLanguage, language, languageDefinition, location.pathname]);

  return (
    <Layout bannerData={bannerData}>
      <TokenExpiredModal />
      <SiteRulesUpdatedModal />
      <Outlet />
    </Layout>
  );
};

const routes = [
  {
    element: <AppLayout />,
    children: [
      { key: "*", path: "*", element: <Error code={404} /> },
      {
        key: "/",
        path: "/",
        element: <RedirectToLv />,
      },
      {
        key: "home",
        path: "/:lang",
        element: <Title />,
      },
      {
        key: "textual",
        path: "/textual",
        element: <Textual />,
      },
      {
        key: "register",
        path: "/register",
        element: <Register />,
      },
      {
        key: "finish-registration",
        path: "/finish-registration/:key",
        element: <Register />,
      },
      {
        key: "email-verified",
        path: "/email-verified/:key",
        element: <ConfirmedEmailPage />,
      },

      {
        key: "manaskopas",
        path: "/manaskopas",

        children: [
          { key: "list", path: "list", element: <ManasKopas /> },
          { key: "add", path: ":id", element: <AddKopa /> },
        ],
        permissions: [
          "Datu kopas augšupielāde",
          "Datu kopas publicēšanas pārtraukšana",
          "Datu kopas metadatu labošana, darbības ar datu kopu",
          "Datu devēja darba vieta",
          "Datu devēja publicētās datu kopas",
          "Datu kopas augšupielāde",
          "Datu kopas metadatu pievienošana",
          "Datu kopas publicēšanas pārtraukšana",
        ],
      },
      {
        key: "/reports",
        path: "/reports",
        element: <ReportsPage />,
        permissions: [
          "Datu kopas augšupielāde",
          "Datu kopas publicēšanas pārtraukšana",
          "Datu kopas metadatu labošana, darbības ar datu kopu",
          "Datu devēja darba vieta",
          "Datu devēja publicētās datu kopas",
          "Datu kopas augšupielāde",
          "Datu kopas metadatu pievienošana",
          "Datu kopas publicēšanas pārtraukšana",
        ],
      },

      {
        key: "dashboard",
        path: "/dashboard",
        element: <Dashboard />,
        permissions: [
          "Sistēmas administratora darba vieta",
          "Datu administratora darba vieta",
          "Datu devēja darba vieta",
          "Datu ņēmēja darba vieta",
        ],
      },
      {
        key: "catalog",
        path: "/catalog",
        element: <Catalog />,
      },

      {
        key: "card",
        path: "/card/:id",
        element: <Card />,
      },

      {
        key: "messages",
        path: "/messages/:id?",
        element: <Messages />,
        permissions: [
          "Paziņojumi administratoram iesūtnē",
          "Paziņojumi datu ņēmējiem",
          "Paziņojumi datu devējiem",
        ],
      },
      {
        key: "apliecinajumi",
        path: "/apliecinajumi",
        element: <Apliecinajums />,
        permissions: ["Datu devēja apliecinājumi"],
      },
      {
        key: "profile",
        path: "/profile",
        element: <Profile />,
        // permissions: ["Datu devēja profils", "Datu ņēmēja profils"],
      },
      {
        key: "search",
        path: "/search",
        element: <Search />,
      },
      {
        key: "abonetaskopas",
        path: "/abonetaskopas",
        element: <AbonetasKopas />,

        permissions: ["Datu ņēmēja abonētās  datu kopas"],
      },
      {
        key: "palidziba",
        path: "/palidziba/:nid?",
        element: <Help />,
      },
      {
        key: "buj",
        path: "/buj",
        element: <BUJ />,
        permissions: ["Biežāk uzdotie jautājumi"],
      },
      {
        key: "sniedzeji",
        path: "/sniedzeji",
        element: <DatuSniedzeji />,
      },
      {
        key: "genericContent2",
        path: "/:lang/:uri/:uri2",
        element: <GenericDrupalPage />,
      },
      {
        key: "genericContent",
        path: "/:lang/:uri",
        element: <GenericDrupalPage />,
      },
      {
        key: "privatumapolitika",
        path: "/privatumapolitika",
        element: <Textual />,
      },
      {
        key: "konfidencialitate",
        path: "/konfidencialitate",
        element: <BUJ />,
      },
      {
        key: "kontakti",
        path: "/kontakti",
        element: <Textual />,
      },
      {
        key: "passwordcreate",
        path: "/passwordcreate",
        element: <PasswordCreate />,
        permissions: [
          "Jaunas paroles ievade un validācija",
          "Lietotāja paroles maiņa",
          "Lietotāja paroles atūgšana",
        ],
      },
      {
        key: "passwordreset",
        path: "/passwordreset",
        element: <PasswordReset />,
      },
      {
        key: "resetPassword",
        path: "/reset-password/:id",
        element: <PasswordNew />,
      },
      {
        key: "documentation",
        path: "/documentation",
        permissions: ["API dokumentācija (datu devējam)", "API dokumentācija (datu ņēmējam)"],
        element: <Textual />,
      },
      {
        key: "logout",
        path: "/logout",
        element: <Logout />,
      },
    ],
  },
];

const filterRoutes = (routes) => {
  const filtered = [];

  for (const item of routes) {
    // check permissions
    if (item?.permissions) {
      let can = false;
      for (const perm of item.permissions) {
        if (user?.can(perm)) {
          can = true;
          break;
        }
      }
      if (!can) continue;
    }

    // check children
    let children = [];
    if (item?.children) {
      children = filterRoutes(item.children);
    }

    filtered.push({ ...item, children });
  }

  return [...filtered];
};

const RoutesWrapper = () => {
  const filteredRoutes = filterRoutes(routes);
  const router = createBrowserRouter(filteredRoutes);
  return <RouterProvider router={router} />;
};

export default RoutesWrapper;
