import { useCallback, useEffect, useState } from "react";
import "./index.css";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { SearchButton } from "../SearchButton";
import LabelTag from "../../components/label";

const CatalogSearch = ({ keywords, manualInput, setManualInput, placeholder }) => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [resetHover, setResetHover] = useState(false);

  const handleKeywords = useCallback(() => {
    if (inputValue.length < 3) {
      setErrorMessage("minInputLength");
      return;
    }
    setResetHover(!resetHover);
    setManualInput(inputValue);
    setInputValue("");
    setErrorMessage("");
  }, [inputValue, setManualInput, resetHover]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handleKeywords();
      }
    },
    [handleKeywords]
  );

  const handleChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value.length === 0) setErrorMessage("");
  };

  useEffect(() => {
    if (manualInput) {
      setErrorMessage("");
    }
  }, [manualInput]);

  return (
    <div className="catalog-search" style={{ display: "flex", flexDirection: "column" }}>
      <div className="catalog-search-container">
        <div id="searchinput" className={`catalog-search-input ${errorMessage ? "red-outline" : ""}`}>
          <input
            value={inputValue}
            placeholder={placeholder}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className="catalog-search-input-text"
          />
          <div className="navbar-searchbar-icon">
            <SearchButton
              className={errorMessage ? "c-error-stroke" : ""}
              hasText={!!inputValue}
              onClick={() => {
                setErrorMessage("");
                setInputValue("");
              }}
            />
          </div>
        </div>
        <button
          type="button"
          className="catalog-search-button focus-red"
          onClick={() => {
            handleKeywords();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleKeywords();
            }
          }}
        >
          <div className="catalog-search-button-text">
            <Trans i18nKey="search" />
          </div>
        </button>
      </div>

      {errorMessage && (
        <span className="red-error-message" style={{ marginLeft: 40, width: "80vw" }}>
          {t(errorMessage)}
        </span>
      )}

      {keywords && (
        <div className="overall-dropdown-selection">
          {manualInput && (
            <LabelTag
              text={manualInput}
              setText={setManualInput}
              forceNotHovered={resetHover}
              onRemove={() => {
                setManualInput("");
              }
              } />
          )}
        </div>
      )}
    </div>
  );
};

export default CatalogSearch;
