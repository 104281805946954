import React from "react";

const FooterImageComponent = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  
  let imageSource = "";
  // january is 0, december is 11
  if (currentMonth > 9 || currentMonth < 4) {
    imageSource = "-winter";
  } else {
    imageSource = "-summer";
  }

  const className = `footer-up-container${imageSource}`;

  return (
    <div
      className={className}
    />
  );
};

export default FooterImageComponent;
