import { useEffect, useState } from "react";

const CheckBox = ({ text, checked, onClick, disabled, round, oneMandatory, style = {} }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleClick = () => {
    if (!disabled) {
      onClick();
      !oneMandatory && setIsChecked(!isChecked);
    }
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div
      type="checkbox"
      className={`register-info-right-rules-checkbox${disabled ? "-disabled" : ""} focus-red`}
      onClick={handleClick}
      tabIndex={0}
      style={{ ...style }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleClick();
        }
      }}
    >
      <img
        alt="alt"
        className={`register-info-right-rules-checkbox-icon${disabled ? "-disabled" : ""}`}
        src={
          isChecked
            ? round
              ? "/assets/img/radiodot.svg"
              : "/assets/img/checkboxT.png"
            : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        }
        style={{
          backgroundImage: isChecked
            ? round
              ? "url(/assets/img/radiodot.svg)"
              : "url(/assets/img/checkboxT.png)"
            : "none",
          backgroundRepeat: "no-repeat",
          width: 20,
          height: 20,
          backgroundPosition: "center center",
          borderRadius: round ? 50 : 2,
        }}
      />
      <div
        style={{ cursor: disabled ? "auto" : "pointer", width: "100%" }}
        className="register-info-right-rules-checkbox-text"
      >
        {text}
      </div>
    </div>
  );
};

export default CheckBox;
