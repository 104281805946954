import React from "react";
import { Banner } from "../common";

import { useTranslation } from 'react-i18next';

const Textual = () => {
  const { t } = useTranslation();
  const bannerData = {
    title: "Tekstuālā sadaļa",
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: "Atrašanās hierarhijā",
  };
  return (
    <>
      <Banner bannerData={bannerData} />
      <div className="content"></div>
      <div className="content-main">
        <div className="content-main-left">
          <div className="content-main-left-width">
            <span className="content-main-left-width-font1">
              <b>Lorem ipsum dolor sit amet, consectetur</b>
            </span>
            <p className="content-main-left-width-font2">
              <b>Adipiscing elit, sed do eiusmod tempor incididunt</b>
            </p>
            <p className="content-main-left-width-font3">
              Ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
              ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>
            <p className="content-main-left-width-font2">
              <b>Cillum dolore eu fugiat</b>
            </p>
            <p className="content-main-left-width-font3">
              Sed ut perspiciatis unde <b>omnis iste natus error</b> sit voluptatem:
            </p>
            <p>
              <li>accusantium doloremque laudantium</li>
              <li>totam rem aperiam</li>
              <li>eaque ipsa quae ab illo inventore</li>
              <li>veritatis et quasi architecto</li>
            </p>
            <p className="content-main-left-width-font3">
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
              consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro
              quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed
              quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
              voluptatem.
            </p>
            <p className="content-main-left-width-font2">
              <b>Dolorem ipsum </b>
              <span className="content-main-left-width-font2-bold">
                <span className="content-main-left-width-font2-bold-bold">
                  <b>quia dolor</b>
                </span>
              </span>
            </p>
            <div className="content-main-left-width-font4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </div>
            <p className="content-main-left-width-font3">
              Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure <br />
              <span className="content-main-left-width-font3-bold">
                <span className="content-main-left-width-font3-bold-bold">
                  <b>reprehenderit qui in ea voluptate.</b>
                </span>
              </span>
            </p>
          </div>
        </div>
        <div className="content-main-right">
          <div>
            <img alt="alt" src="/assets/img/content.png" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Textual;
