import React from "react";
import Footer from "./footer";
import Header from "./header/header";

import NavBar from "./navbar";
import NotificationBanner from "./notification";

const Layout = ({ children, notifications }) => {
  return (
    <>
      <Header />
      <NavBar />
      {notifications &&
        Array.isArray(notifications) &&
        notifications.map((n) => <NotificationBanner {...n} />)}

      {children}
      <Footer />
    </>
  );
};

export default Layout;
