import Select, { components } from "react-select";
import { t } from "i18next";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div
        className="filter-arrow"
        style={
          {
            //   display: "flex",
            //   width: 25,
            //   left: -5,
          }
        }
      >
        {/* <img
          alt="dropdown arrow"
          src="/assets/img/arrow.svg"
          className="dropdown-arrow filter-arrow"
          style={{
            marginTop: -20,
          }}
        /> */}
      </div>
    </components.DropdownIndicator>
  );
};

const StyledSelect = (props) => {
  const hasError = props.hasError;
  return (
    <Select
      components={{
        DropdownIndicator,
        Menu: (props) => <components.Menu {...props} className="filters-menu" />,
        Option: (props) => <components.Option {...props} className="filters-option" />,
        // IndicatorSeparator: () => null,
      }}
      //   controlShouldRenderValue={false}
      noOptionsMessage={() => t("noOptions")}
      styles={{
        control: (base, state) => {
          return {
            ...base,
            border: "2px solid var(--c_input_border)",
            backgroundColor: "var(--background-color)",
            borderRadius: "5px",
            boxShadow: "none",
            outline: state.isFocused
              ? "4px solid var(--c_light_blue)"
              : hasError
              ? "2px solid var(--c_error)"
              : "none",
            "&:hover": {
              outline: "4px solid var(--c_light_blue)",
              opacity: 0.8,
            },
          };
        },
        valueContainer: (base) => ({
          ...base,
          paddingLeft: 20,
        }),
        menuList: (base) => ({
          ...base,
          backgroundColor: "var(--background-color)",
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          transition: "all .2s ease",
          transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : null,
        }),
        option: (styles, { isFocused, isSelected, index }) => ({
          ...styles,
          background:
            isFocused && isSelected
              ? "var(--c_input_option_focused_selected)"
              : isFocused
              ? "var(--c_input_option_focused)"
              : isSelected
              ? "var(--c_input_option_selected)"
              : "",
          color: isSelected ? "white" : "black",
          zIndex: 1,
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: "#F5F5F5",
          borderRadius: 4,
          padding: "2px 4px",
        }),
      }}
      {...props}
    />
  );
};

export { StyledSelect };
