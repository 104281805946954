import React, { useContext, useState } from "react";
import "./index.css";
import PrimaryButton from "../common/atoms/primarybutton";
import ModalComponent from "./modal";
import { StateContext } from "../context";
import CheckBox from "../common/checkbox";
import DeleteModal from "./deletemodal";
import { useTranslation } from "react-i18next";
import { postCms } from "../classes/cmsApi";

const ReportModal = ({ trigger, nid }) => {

  const [errorMessage, setErrorMessage] = useState("");
  const [reportContent, setReportContent] = useState("");
  const [active, setActive] = useState("1");

  const { handleModalOpen, handleModalClose } = useContext(StateContext);
  const { t } = useTranslation();
  const form = [
    {
      id: "1",
      text: t("popupMessageQuestion"),
    },
    {
      id: "2",
      text: t("popupMessageReportABug"),
    },
    {
      id: "3",
      text: t("popupMessageGenericMessage"),
    },
  ];

  const sendReport = async () => {
    if (reportContent === "") {
      setErrorMessage(t("descFieldMandatory"));
    }
    const result = await postCms(`api/v1/add/report`, { nid, option: active, content: reportContent });

    if (result?.error) {
      console.error(result?.error);
      return;
    }
    
    setReportContent("");
    handleModalOpen("report2");
  };

  return (
    <>
      <ModalComponent
        id="report"
        trigger={trigger}
        small
        title={t("popupMessageOrAsk")}
        className={"modal-stack rate-modal"}
      >
        <>
          <div className="edit-profile-modal-content">
            <div style={{ marginTop: 34 }} />
            {form.map((item) => (
              <CheckBox
                key={item.text}
                text={item.text}
                checked={item.id === active}
                round
                onClick={() => {
                  if (item.id !== active) {
                    setActive(item.id);
                  }
                }}
                oneMandatory={true}
              />
            ))}
            <div style={{ marginTop: 30 }}>
              <textarea
                className={errorMessage ? "report-modal-error red-outline" : ""}
                onChange={(e) => {
                  setReportContent(e.target.value);
                }}
                placeholder={errorMessage || t("enterYourMessage")}
                value={reportContent}
                style={{ resize: "none", minHeight: "7rem", width: "100%" }}
                maxLength={250}
              />
              <div>
                {reportContent.length} / 250
              </div>
              {/* <Input
              placeholder={"@ E-pasts atbildes saņemšanai"}
            /> */}
              <div style={{ marginTop: 32 }} />
            </div>
          </div>
          <PrimaryButton
            style={{
              height: 64,
              width: 134,
              textAlign: "center",
              justifyContent: "center",
              padding: 0,
            }}
            title={t("popupMessageSend")}
            hideSvg
            onClick={() => {
              sendReport();
            }}
          />
        </>
      </ModalComponent>

      <DeleteModal
        id="report2"
        modalTtile={t("popupMessageOrAsk")}
        title={t("thanksYourReportNoted")}
        subtitle={nid ? (active === "2" ? t("hasBeenSentToDataProviderAndAdmins") : t("hasBeenSentToDataProvider")) : t("hasBeenSentToAdmins")}
        link={t("responseHere")}
        buttonTitle={t("popupMessageClose")}
        navLink="/messages"
        onButtonPress={() => {
          handleModalClose("report2");
        }}
      />
    </>
  );
};

export default ReportModal;
