import { useTranslation } from "react-i18next";
import NotificationBanner from "../../common/notification";
import { useEffect } from "react";
import { auth } from "../../classes/auth";

const ConfirmedEmailPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    if (auth.isAuthorized()) auth.logout();
  }, []);

  return <NotificationBanner text={t("emailConfirmed")} backgroundColor="#56595a" canClose={false} />;
};

export default ConfirmedEmailPage;
