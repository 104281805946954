import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StateContext } from "../../context";
import { Banner, CatalogSearch } from "../../common";
import "./index.css";
import { getCms } from "../../classes/cmsApi";
import Loader from "../../common/loader";
import { sanitize } from "dompurify";
import { colors } from "../../helpers/contrast";

const loadMoreIncrement = 5;

const latvianMap = {
  ā: "a",
  č: "c",
  ē: "e",
  ģ: "g",
  ī: "i",
  ķ: "k",
  ļ: "l",
  ņ: "n",
  š: "s",
  ū: "u",
  ž: "z",
  Ā: "A",
  Č: "C",
  Ē: "E",
  Ģ: "G",
  Ī: "I",
  Ķ: "K",
  Ļ: "L",
  Ņ: "N",
  Š: "S",
  Ū: "U",
  Ž: "Z",
};

export const removeLatvianDiacritics = (str) => {
  return str
    .split("")
    .map((char) => latvianMap[char] || char)
    .join("");
};

export const convertToRGBA = (colorName, alpha) => {
  const colors = {
    black: "77, 78, 80",
    yellow: "200, 200, 0",
    white: "200, 200, 200",
  };
  const rgb = colors[colorName.toLowerCase()];
  return rgb ? `rgba(${rgb}, ${alpha})` : colorName;
};

export const highlightKeyword = (words, keyword, foregroundColor, backgroundColor) => {
  const highlightedHTML = words
    .map((word) => {
      if (
        removeLatvianDiacritics(word).toLowerCase().includes(removeLatvianDiacritics(keyword).toLowerCase())
      ) {
        return `<span style="background-color:${convertToRGBA(
          foregroundColor,
          0.7
        )}; color:rgba(${backgroundColor}, 0.4);">${word}</span>`;
      }
      return word;
    })
    .join(" ");
  return highlightedHTML;
};

export const unquotify = (initial_text) => {
  const charRefsToReplace = {
    "&quot;": '"',
    "&lt;": "<",
    "&gt;": ">",
    "&amp;": "&",
    "&nbsp;": " ",
    "&#039;": "'",
  };
  if (!initial_text || typeof initial_text !== "string") return "";

  for (const [charRef, char] of Object.entries(charRefsToReplace)) {
    initial_text = initial_text.replace(new RegExp(charRef, "g"), char);
  }
  return initial_text;
};

const Search = () => {
  const [filters, setFilters] = useSearchParams();
  const navigate = useNavigate();
  const { language, contrast } = useContext(StateContext);
  const { t } = useTranslation();
  const inputValue = useMemo(() => filters.get("searchValue") ?? "", [filters]);
  const page = useMemo(() => filters.get("page") ?? "", [filters]);
  const [totalResults, setTotalResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moreResultsLoading, setMoreResultsLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [total, setTotal] = useState(0);

  const { foregroundColor, backgroundColor } = colors[contrast] || colors["contrast_blue"];

  useEffect(() => {
    const currentPage = Number(page);
    let fetchExtra = false;

    if (currentPage === 0) {
      setTotalResults([]);
    } else {
      const totalResultsNeeded = currentPage * loadMoreIncrement;
      if (totalResults.length < totalResultsNeeded) {
        fetchExtra = true;
      }
    }
    const fetchData = async () => {
      try {
        if (!inputValue) {
          setTotalResults([]);
          setTotal(0);
          setFilters((current) => {
            current.set("page", 0);
            return current;
          });
          return;
        }
        if (!moreResultsLoading) setLoading(true);
        const pageToFetch = fetchExtra ? 0 : currentPage;
        const resultsToFetch = fetchExtra ? (currentPage + 1) * loadMoreIncrement : loadMoreIncrement;
        const fetchedData = await getCms(
          `public/global-search-v1?search_api_fulltext=${inputValue.toLowerCase()}&items_per_page=${resultsToFetch}&page=${pageToFetch}`,
          language
        );
        setTotal(fetchedData.pager.total_items);
        const nextPageData = await getCms(
          `public/global-search-v1?search_api_fulltext=${inputValue.toLowerCase()}&items_per_page=${loadMoreIncrement}&page=${
            currentPage + 1
          }&langcode=${language}`,
          language
        );
        setIsLastPage(!!nextPageData.rows.length);

        let newResults = [];
        let newNotInBodyResults = [];
        let newDatuKopas = [];

        // process fetched data
        fetchedData.rows.forEach((item) => {
          // PALĪDZĪBAS LAPA
          if (item?.type === "palidzibas_lapa") {
            const bodyText = extractText(item?.body);
            if (bodyText[0] === "hasInputWordInBodyOrTitle") {
              newResults.push({
                title: item?.field_palidzibas_lapas_nosaukums || "Nezināms nosaukums",
                text: bodyText[1],
                path: item?.nid ? `/palidziba/${item?.nid}` : undefined,
              });
            } else {
              newNotInBodyResults.push({
                title: item?.field_palidzibas_lapas_nosaukums || "Nezināms nosaukums",
                text: bodyText[1],
                path: item?.nid ? `/palidziba/${item?.nid}` : undefined,
              });
            }
          }
          // DATU KOPA
          else if (item?.type === "data_set_metadata") {
            newDatuKopas.push({
              title:
                language === "lv"
                  ? item?.field_dataset_name_lv || "Nezināms nosaukums"
                  : item?.field_dataset_name || "Unknown name",
              text: extractText(
                language === "lv"
                  ? item?.field_dataset_description_lv ?? ""
                  : item?.field_dataset_description ?? ""
              )[1],
              path: item?.uuid ? `/card/${item?.uuid}` : undefined,
            });
          }
          // PARASTS TEKSTS
          else {
            const bodyText = extractText(item?.body);
            if (bodyText[0] === "hasInputWordInBodyOrTitle") {
              newResults.push({
                title: item.title || "Nezināms nosaukums",
                text: bodyText[1],
                path: item?.search_api_url ? `${item?.search_api_url}` : undefined,
              });
            } else {
              newNotInBodyResults.push({
                title: item.title || "Nezināms nosaukums",
                text: bodyText[1],
                path: item?.search_api_url ? `${item?.search_api_url}` : undefined,
              });
            }
          }
        });

        setTotalResults((prevTotalResults) => [
          ...prevTotalResults,
          ...newResults,
          ...newNotInBodyResults,
          ...newDatuKopas,
        ]);
      } catch (error) {
        console.error("error:", error);
        setTotalResults([]);
      } finally {
        setLoading(false);
        setMoreResultsLoading(false);
      }
    };

    const extractText = (initial_text) => {
      if (!initial_text) return "";

      // remove images and multiple spaces
      const text = initial_text
        .replace(/<img[^>]*>/g, "")
        .replace(/(<br\s*\/?>\s*)+/gi, " ")
        .replace(/\s+/g, " ");
      const normalizedInput = removeLatvianDiacritics(inputValue).toLowerCase();
      const cleanedText = text.replace(/<\/?[^>]+(>|$)/g, " ").replace(/\s+/g, " ");
      const words = cleanedText.split(
        /(?<=\D)\s|(?<=\d)\s|(?<=\s)\d|(?<=[a-zA-Z])(?=[.,;!?])|(?<=[.,;!?])(?=[a-zA-Z])|(?<=[a-zA-Z])(?=\d)|(?<=\d)(?=[a-zA-Z])/
      );

      let lastEnd = -1;
      let currentStart = 999999;
      const matches = [];
      const fromBegginning = [false, false, false];
      const fromEnd = [false, false, false];
      const hasInput = (word) => {
        return removeLatvianDiacritics(word).toLowerCase().includes(normalizedInput);
      };
      words.forEach((word, index) => {
        if (hasInput(word)) {
          if (currentStart === 999999) {
            currentStart = Math.max(0, index - 20);
          }
          lastEnd = Math.min(words.length, index + 19);
          if (matches.length >= 2 && index > lastEnd) {
            return;
          }
        }
        if (index > lastEnd && currentStart !== 999999) {
          const snippet = words
            .slice(currentStart, lastEnd)
            .map((w) =>
              hasInput(w)
                ? `<span style="background-color:${convertToRGBA(
                    foregroundColor,
                    0.5
                  )} !important; color:${backgroundColor} !important;">${w}</span>`
                : w
            )
            .join(" ");
          if (currentStart > 0) {
            fromBegginning[matches.length] = true;
          }
          if (lastEnd < words.length) {
            fromEnd[matches.length] = true;
          }
          matches.push(snippet);
          currentStart = 999999;
        }
      });
      // last match
      if (currentStart !== 999999 && matches.length < 3) {
        const snippet = words
          .slice(currentStart, lastEnd)
          .map((w) =>
            hasInput(w)
              ? `<span style="background-color:${convertToRGBA(
                  foregroundColor,
                  0.7
                )} !important; color:${backgroundColor} !important;">${w}</span>`
              : w
          )
          .join(" ");
        if (currentStart !== 999999) {
          fromBegginning[matches.length] = true;
        }
        if (lastEnd < words.length) {
          fromEnd[matches.length] = true;
        }
        matches.push(snippet);
      }

      const findLastPeriodBeforeInput = (words, inputIndex) => {
        let lastGoodIndex = 0;
        for (let i = inputIndex - 1; i > 0; i--) {
          if (words[i].endsWith(".") || words[i].endsWith(" ;") || words[i] === ";") {
            return i + 1;
          }
        }
        return lastGoodIndex;
      };

      const findNextPeriodAfterInput = (words, inputIndex) => {
        for (let i = inputIndex + 1; i < words.length; i++) {
          if (words[i].endsWith(".") || words[i].endsWith(" ;") || words[i] === ";") {
            return i;
          }
        }
        return words.length;
      };

      const newMatches = matches.map((snippet) => {
        const words = snippet.split(/\s+/);
        let inputWordIndex = words.findIndex(hasInput);
        let startFromIndex = findLastPeriodBeforeInput(words, inputWordIndex);
        let endAtIndex = findNextPeriodAfterInput(words, inputWordIndex);
        const newSnippetWords = words.slice(startFromIndex, endAtIndex);
        return newSnippetWords.join(" ");
      });
      // TODO nav skaidrs dēļ neskaidrībām LS, vai vajag arī atrādīt tos, kuri nesatur tiešā teksta veidā doto ievadu
      if (!matches.length) {
        const words = text.split(/\s+/);
        return [
          "doesNotHaveInputWordInBodyOrTitle",
          words.slice(0, 15).join(" ") + (words.length > 15 ? " ..." : ""),
        ];
      }

      return [
        "hasInputWordInBodyOrTitle",
        newMatches
          .slice(0, 2)
          .map((snippet, index) => {
            return (fromBegginning[index] ? "... " : "") + snippet + (fromEnd[index] ? " ..." : "");
          })
          .join("<br><br>"),
      ];
    };

    fetchData();
  }, [language, inputValue, contrast, foregroundColor, backgroundColor, page]);

  const handleLoadMoreClick = async (event) => {
    event.preventDefault();
    const nextPage = Number(page) + 1;

    setFilters((current) => {
      const newFilters = new URLSearchParams(current);
      newFilters.set("page", nextPage);
      return newFilters;
    });
    setMoreResultsLoading(true);
    setLoading(true);
  };

  const handleTitleClick = (path) => {
    if (path) navigate(path);
  };

  function getTextEnding(count, textSingular, textMultiple) {
    const isSingular = count === 1 || (language === "lv" && count % 10 === 1 && count % 100 !== 11);
    return isSingular ? textSingular : textMultiple;
  }

  const bannerData = {
    title: (
      <>
        {t("foundStart") + getTextEnding(total, t("foundStartOne"), t("foundStartMultiple")) + " "}
        {!loading ? (
          total
        ) : (
          <span style={{ display: "inline-block" }}>
            <Loader style={{ display: "inline-block", padding: "0px" }} />
          </span>
        )}
        {t("foundEnd")}
        {getTextEnding(total, t("foundEndOne"), t("foundEndMultiple"))}
      </>
    ),

    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("searchResults"),
  };

  function renderItem(item, index) {
    const normalizedInput = removeLatvianDiacritics(inputValue).toLowerCase();
    const words = item.title.split(/\s+/);

    const highlightedTitle = highlightKeyword(words, normalizedInput, foregroundColor, backgroundColor);
    return (
      <div
        key={index}
        className="item"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleTitleClick(item?.path);
        }}
      >
        <div
          className={`${item?.path ? "title" : "title-inactive"}`}
          onClick={() => {
            handleTitleClick(item?.path);
          }}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <div dangerouslySetInnerHTML={{ __html: sanitize(highlightedTitle) }} className="title-text"></div>
          <img alt="alt" src="/assets/img/next.svg" className="arrow" />
        </div>

        <span
          className="custom-br-spacing"
          dangerouslySetInnerHTML={{ __html: sanitize(unquotify(item.text)) }}
        ></span>
      </div>
    );
  }

  return (
    <>
      <Banner bannerData={bannerData} />
      <div className="container">
        <CatalogSearch
          placeholder={t("searchPlaceHolder")}
          keywords
          manualInput={filters.get("searchValue") ?? ""}
          setManualInput={(val) => {
            setFilters((current) => {
              if (val) {
                if (val !== filters.get("searchValue")) {
                  current.set("searchValue", val);
                  current.set("page", 0);
                  setTotalResults([]);
                }
              } else {
                current.delete("searchValue");
                // setTotalResults([])
              }
              return current;
            });
          }}
        />
        {loading && !moreResultsLoading ? (
          <Loader style={{ display: "inline-block", padding: "0px" }} />
        ) : totalResults.length === 0 ? (
          <div className="catalog-main-menu no-data">
            <span className="page-error-message">{t("noData")}</span>
            <img alt={t("noResults")} src={`/assets/img/errorguy.png`} />
          </div>
        ) : (
          <div className="results-container">
            {totalResults.map(renderItem)}

            {/* LOAD MORE */}
            {isLastPage && (
              <div className="search-load-more-div">
                {!moreResultsLoading ? (
                  <button className="search-load-more-button focus-red" onClick={handleLoadMoreClick}>
                    <img className="search-load-more-icon" alt="alt" src="/assets/img/load_more-icon.svg" />
                    {t("loadMore")}
                  </button>
                ) : (
                  <Loader style={{ display: "inline-block", padding: "0px" }} />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Search;
