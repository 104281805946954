import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dropdowns from "./components/dropdown";
import User from "./components/user";
import { StateContext } from "../../context";
import { auth } from "../../classes/auth";

const SlidingImages = () => {
  const { language } = useContext(StateContext);
  const images = [`/assets/img/npp-minislide-${language}1.svg`, `/assets/img/npp-minislide-${language}2.svg`];
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex(imageIndex === 0 ? 1 : 0);
    }, 4000);

    return () => clearInterval(intervalId);
  }, [imageIndex]);

  return <img alt="alt" className="small-slidebar-slides" src={images[imageIndex]} />;
};

const Header = () => {
  const navigate = useNavigate();
  const { language } = useContext(StateContext);

  return (
    <div className="header">
      <div
        className="header-all-logo"
        style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}
        onClick={() => navigate(`/${language}`)}
      >
        <div className="header-logo-left-div">
          <img
            alt="alt"
            src={`/assets/img/npp-logo-${language}.svg`}
            className="header-logo-left focus-red"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate(`/${language}`);
              }
            }}
          />
        </div>
        <div
          className="small-slidebar-slides-div focus-red"
          onClick={(event) => {
            const clickX = event.clientX - event.currentTarget.getBoundingClientRect().left;

            if (clickX < event.currentTarget.offsetWidth / 2 - 30) {
              // kreisā puse
              window.open("https://lvceli.lv/");
            } else {
              // labā puse
              window.open("https://www.sam.gov.lv/lv");
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              window.open("https://www.sam.gov.lv/lv");
            }
          }}
          tabIndex={0}
        >
          <SlidingImages />
        </div>
      </div>
      <div className="header-setting-env" style={{ marginTop: auth.isAuthorized() ? 5 : 18 }}>
        <User />
      </div>
    </div>
  );
};

export default Header;
