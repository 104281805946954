import { useContext, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCms } from "../../classes/cmsApi";
import { Banner, CatalogSearch } from "../../common";
import { StateContext } from "../../context";
import { Filters, useDropdowns } from "./components/filters";
import CatalogList from "./components/catalogList";
import Paginate from "../../components/Paginate";
import useSuperDuperReliableSmoothScroll from "../../hooks/useSuperDuperReliableSmoothScroll";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../common/loader";
import { unquotify } from "../search/search";

const fetchPublicMetadataList = async (filters, dropdowns, searchProp, language, page) => {
  const params = new URLSearchParams(filters.toString());
  params.delete("page");


  if (params.has("datasetName")) {
    params.set(searchProp, params.get("datasetName"));
    params.delete("datasetName");
  }


  const passedParams = Object.fromEntries(params);
  if (passedParams?.field_format_model_value) {
    const modelInDropdowns = dropdowns.find((dropdown) => dropdown.key === "field_format_model_value")?.items.find(
      (item) => item.key === passedParams?.field_format_model_value);
    if (modelInDropdowns?.schemas) {
      passedParams.field_format_model_parent_value = passedParams?.field_format_model_value;
      delete passedParams.field_format_model_value;
    }
  }
  
  const data = await getCms("public/dataset-search", language, passedParams, page - 1, null, {});

  data.rows = data.rows.map((row) => {
    for (const key in row) {
      row[key] = unquotify(row[key]);
    }
    return row;
  });

  return data;
};

const CatalogMain = ({ filters, setFilter, resetFilters, setPage }) => {
  const { language } = useContext(StateContext);
  const { t } = useTranslation();
  const searchProp = useMemo(
    () => (language === "lv" ? "field_dataset_name_lv" : "field_dataset_name"),
    [language]
  );

  const datasetName = filters.get("datasetName");

  const page = useMemo(() => filters.get("page") ?? 1, [filters]);

  const {dropdowns, isLoading: isDropdownLoading , isFetching: isDropdownFetching } = useDropdowns();

  const { data, isLoading, isError, isFetching } = useQuery(
    [`catalog`, filters.toString(), language, page, searchProp, dropdowns],
    async () => {
      return await fetchPublicMetadataList(filters, dropdowns, searchProp, language, page);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return (
    <div className="catalog-main">
      <div className="catalog-main-left">
        <Filters
          dropdowns={dropdowns}
          filters={filters}
          setFilter={setFilter}
          resetFilters={resetFilters}
          totalItems={data?.pager?.total_items ?? 0}
        />
      </div>
      <div className="catalog-main-right">
      {(isLoading || isFetching || isDropdownLoading || isDropdownFetching) ? (
        <Loader />
      ) : (
        <>
        {data?.rows?.length > 0 && !isError && (
          <>
            <div className="catalog-main-menu">
              <CatalogList data={data.rows ?? []} searchKeyword={datasetName ?? ""} />
            </div>
            <Paginate
              setPage={setPage}
              page={isNaN(page) ? 1 : Number(page)}
              maxPages={data.pager?.total_pages ?? 0}
            />
          </>
        )}
        {(data?.rows?.length === 0 || isError) && (
          <div className="catalog-main-menu no-data">
            <span className="page-error-message">{t("noData")}</span>
            <img alt={t("noData")} src={`/assets/img/errorguy.png`} />
          </div>
        )}
        </>
      )}
      </div>
    </div>
  );
};

const Catalog = () => {
  const [filters, setFilters] = useSearchParams();
  const { t } = useTranslation();

  const bannerData = {
    title: t("headerDataCatalog"),
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("headerDataCatalog"),
  };

  useSuperDuperReliableSmoothScroll([filters], "#DK-header");

  const setPage = (page) => {
    setFilters((current) => {
      current.set("page", page);
      return current;
    });
  };

  const setFilter = (selected) => {
    let filtersChanged = false;

    // Check if any filter has changed
    for (const [key, value] of Object.entries(selected)) {
      if (filters.get(key) !== value && value) {
        filtersChanged = true;
        break;
      }
    }

    if (filtersChanged) {
      setPage(1);
    }
    setFilters((current) => {
      for (const [key, value] of Object.entries(selected)) {
        
        if (key === "field_content_category_parent_target_id") {
          current.delete("field_content_category_target_id");
        }

        if (value !== undefined && value !== null) {
          current.set(key, value);
        } else {
          current.delete(key);
        }
      }

      return current;
    });
  };

  const resetFilters = () => {
    setFilters((current) => {
      return new URLSearchParams({
        page: 1,
        ...(current.get("sort_by") && { sort_by: current.get("sort_by") }),
      });
    });
  };

  return (
    <>
      <Banner bannerData={bannerData} id="DK-header" />
      <div className="catalog">
        <CatalogSearch
          placeholder={t("homeSpecifySearchContentCatalogue")}
          keywords
          manualInput={filters.get("datasetName") ?? ""}
          setManualInput={(val) => {
            setFilters((current) => {
              if (val) current.set("datasetName", val);
              else current.delete("datasetName");

              return current;
            });
            setPage(1);
          }}
        />
        <CatalogMain filters={filters} setFilter={setFilter} resetFilters={resetFilters} setPage={setPage} />
      </div>
    </>
  );
};

export default Catalog;
