import { BACKEND_URL, get } from "./api";
import { auth } from "./auth";

class User {
  constructor() {
    this.empty();
    this.prefill();
  }
  prefill() {
    const userdata = auth.getUserData();
    if (userdata && auth.isAuthorized()) {
      this.sub = userdata.sub;
      this.username = userdata.preferred_username;
      this.givenName = userdata.given_name;
      this.familyName = userdata.family_name;
      this.email = userdata.email;
      this.emailVerified = userdata.email_verified;
      this.juridicalPersonAddress = userdata.juridicalPersonAddress;
      this.juridicalPersonName = userdata.juridicalPersonName;
      this.juridicalPersonRegNr = userdata.juridicalPersonRegNr;
      this.logoFileUrl = userdata.logoFileUrl;
      // this.maxReqPerPeriod = userdata.maxReqPerPeriod;
      this.country = userdata.country;
      this.phoneNr = userdata.phoneNr;
      // this.reqLimit = userdata.reqLimit;
      // this.reqLimitPeriod = userdata.reqLimitPeriod;
      this.secondEmail = userdata.secondEmail;
      this.website = userdata.website;
      this.permissions = userdata.permissions;
      this.roles = [userdata.roles];
      this.profileName = userdata.profileName;
    }
  }

  empty = () => {
    this.sub = null;
    this.username = null;
    this.givenName = null;
    this.familyName = null;
    this.email = null;
    this.emailVerified = null;
    this.juridicalPersonAddress = null;
    this.juridicalPersonName = null;
    this.juridicalPersonRegNr = null;
    this.logoFileUrl = null;
    // this.maxReqPerPeriod = null;
    this.country = null;
    this.phoneNr = null;
    // this.reqLimit = null;
    // this.reqLimitPeriod = null;
    this.secondEmail = null;
    this.website = null;
    this.permissions = [];
    this.roles = [];
    this.profileName = null;
  };

  refresh = async (callback) => {
    if (auth.isAuthorized()) {
      this.prefill();
      // Check endpoint for any changed data
      try {
        const resp = await get(`${BACKEND_URL}/api/me`);
        if (resp.data.data) {
          this.sub = resp.data.data.sub;
          this.username = resp.data.data.preferred_username;
          this.givenName = resp.data.data.given_name;
          this.familyName = resp.data.data.family_name;
          this.email = resp.data.data.email;
          this.emailVerified = resp.data.data.email_verified;
          this.juridicalPersonAddress = resp.data.data.juridicalPersonAddress;
          this.juridicalPersonName = resp.data.data.juridicalPersonName;
          this.juridicalPersonRegNr = resp.data.data.juridicalPersonRegNr;
          this.logoFileUrl = resp.data.data.logoFileUrl;
          this.country = resp.data.data.country;
          this.phoneNr = resp.data.data.phoneNr;
          this.secondEmail = resp.data.data.secondEmail;
          this.website = resp.data.data.website;
          this.permissions = resp.data.data.permissions;
          this.roles = [resp.data.data.roles];
          this.agreedToTermsOfService = resp.data.data.agreedToTermsOfService;
          this.profileName = resp.data.data.profileName;
        }
        if (typeof callback === 'function') {
          callback({ ...this });
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      this.empty();
      if (typeof callback === 'function') {
        callback(this);
      }
    }
  };

  can = (permission) => {
    return this.permissions.includes(permission);
  };
  is = (role) => {
    return this.roles[0]?.includes(role);
  };
  displayProfileName = () => {
    return this.profileName || this.givenName || "";
  };
}

const usr = new User();
export { usr };
