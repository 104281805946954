import { useEffect, useState } from "react";
import { DataProvide, NotificationBanner } from "../../common";
import Categories from "./components/categories";
import SlideBar from "./components/slidebar";
import { fetch } from "../../classes/auth";
import { sanitize } from "dompurify";
import { t } from "i18next";

const data = [
  {
    id: "1",
    title: "Latvijas Valsts Ceļi",
    href: "lvceli.lv",
    img: "/assets/img/lvc-logo.svg",
  },
  {
    id: "2",
    title: "Latvijas Vides ģeoloģijas un meteoroloģijas centrs",
    href: "videscentrs.lvgmc.lv",
    img: "/assets/img/LVGlogo.png",
  },
];

const Title = () => {
  const [infoMessages, setInfoMessages] = useState([]);
  const [alertMessages, setAlertMessages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetch(process.env.REACT_APP_CMS_API + process.env.REACT_APP_NOTIFICATIONS, {})
          .then((response) => response.json())
          .then((data) => {
            const infoList = data.filter((item) => item.type === "information");
            infoList.sort((a, b) => b.created - a.created);
            setInfoMessages(infoList);

            const alertList = data.filter((item) => item.type === "problem");
            alertList.sort((a, b) => b.created - a.created);
            setAlertMessages(alertList);
          })
          .catch((error) => {});
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleCloseInfoMessage = (index) => {
    const updatedMessages = [...infoMessages];
    updatedMessages.splice(index, 1);

    setInfoMessages(updatedMessages);
  };

  const handleCloseAlertMessage = (index) => {
    const updatedMessages = [...alertMessages];
    updatedMessages.splice(index, 1);

    setAlertMessages(updatedMessages);
  };

  return (
    <div className="component-wrapper">
      {alertMessages.map((item, index) => {
        return (
          <NotificationBanner
            key={index}
            onClose={() => handleCloseAlertMessage(index)}
            svg="alret"
            backgroundColor="#9e313a"
            text={<div dangerouslySetInnerHTML={{ __html: sanitize(item.message.replace(/<\/?p>/g, "")) }} />}
          />
        );
      })}

      {infoMessages.map((item, index) => {
        return (
          <NotificationBanner
            key={index}
            onClose={() => handleCloseInfoMessage(index)}
            svg="alret"
            backgroundColor="#00CCF5"
            text={<div dangerouslySetInnerHTML={{ __html: sanitize(item.message.replace(/<\/?p>/g, "")) }} />}
          />
        );
      })}

      <SlideBar />
      <Categories />
      {(window.location.href.includes("test") || process.env.NODE_ENV === "development") && (
        <DataProvide data={data} limit={2} title={t("titleDataProvidedBy")} />
      )}
    </div>
  );
};

export default Title;
