import { useEffect } from "react";
import { Banner, DataProvide } from "../common";
import { useTranslation } from 'react-i18next';

const DatuSniedzeji = () => {
  const { t } = useTranslation();
  const bannerData = {
    title: t("NAPDataGivers"),
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("DataGivers"),
    path2Url: "/sniedzeji"
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Banner bannerData={bannerData} />
      <DataProvide  btn />
    </>
  );
};

export default DatuSniedzeji;
