import { useContext, useEffect, useState } from "react";
import { AuthBox, Banner } from "../../common";
import Table from "./components/table";
import { deleteCms, getCms, postCms } from "../../classes/cmsApi";
import { useQuery } from "@tanstack/react-query";
import { StateContext } from "../../context";
import Loader from "../../common/loader";
import { useTranslation } from 'react-i18next';
import Paginate from "../../components/Paginate";
import { useParams } from 'react-router-dom';
import { usr as user } from "../../classes/user";
import { useQueryClient} from "@tanstack/react-query";

const Messages = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [currentlyDeletedMessages, setCurrentlyDeletedMessages] = useState([]);
  const bannerData = {
    title: t("myMessages"),
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("menuProfileFeatures"),
    path2Url: "/dashboard",
    path3: t("dropdownInbox"),
  };
  const { language } = useContext(StateContext);
  const { data, isLoading, isError, error, refetch } = useQuery(
    [`messages`, page],
    async () => {
      return await getCms(`api/v1/user/messages/list?page=${page - 1}&items_per_page=10`, language);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: specificData } = useQuery(
      [id],
      async () => {
        return await getCms(`api/v1/user/messages/list/specific/${id}`, language);
      },
      {
        enabled: !!id,
        refetchOnWindowFocus: false,
      }
    );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  if (isLoading) return <Loader />;
  if (isError) {
    console.error(error);
    return null;
  }

  const handleDelete = async ({ nid }) => {
    try {

      setCurrentlyDeletedMessages((prev) => [...prev, nid]);

      await deleteCms("api/v1/delete/message", { nid });
      // also refresh unreadMessage count on seen
      queryClient.invalidateQueries(['unreadMessages', language]);
      const { data: updatedRows } = await refetch();

      if (updatedRows?.rows?.length === 0 && page > 0) {
        setPage((prevPage) => prevPage - 1);
      }
    } catch (error) {
      console.error(error?.error ?? "Notika kļūda");
    } finally {
      setCurrentlyDeletedMessages((prev) => prev.filter((item) => item !== nid));
    }

  };

  const markSeen = async (message) => {
    try {
      await postCms("api/v1/user/messages/list/seen", message);
      // also refresh unreadMessage count on seen
      queryClient.invalidateQueries(['unreadMessages', language]);
    } catch (error) {
      console.error(error?.error ?? "Notika kļūda");
    }

    rows = rows.map((item) => {
      if (item.nid === message?.nid) {
        item.field_seen_by = user?.email;
      }
      return item;
    })
  }

  let { rows } = data;

  return (
    <>
      <Banner bannerData={bannerData} children={<AuthBox />} />
      <Table data={rows} handleDelete={handleDelete} currentlyDeletingKeys={currentlyDeletedMessages} refetch={refetch} specificData={specificData} markSeen={markSeen} />
      {rows.length ? <Paginate setPage={setPage} page={page} maxPages={data.pager.total_pages} /> : <></>}
    </>
  );
};

export default Messages;
