import { useCallback, useEffect, useState } from "react";
import i18n from "i18next";

// Custom hook to store state in local storage
export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        if (keyName === "language") {
          i18n.changeLanguage(JSON.parse(value));
        }
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        if (keyName === "language") {
          i18n.changeLanguage(defaultValue);
        }
        return defaultValue;
      }
    } catch (err) {
      if (keyName === "language") {
        i18n.changeLanguage(defaultValue);
      }
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    if (keyName === "language") {
      i18n.changeLanguage(newValue);
    }
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};

export const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    const jsonValue = window.sessionStorage.getItem(key)
    if (jsonValue != null) return JSON.parse(jsonValue)

    if (typeof defaultValue === "function") {
      return defaultValue()
    } else {
      return defaultValue
    }
  })

  useEffect(() => {
    if (value === undefined) return window.sessionStorage.removeItem(key)
    window.sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  const remove = useCallback(() => {
    setValue(undefined)
  }, [])

  return [value, setValue, remove]
}