export const ErrorMessage = ({ text, onClose }) => {
  return (
    <div
      className="authorize-main-left-alert"
      style={{ marginBottom: 10, marginTop: 10, height: 30 }}
    >
      <div className="authorize-main-left-alert-text">{text}</div>
      {onClose && (
        <img
          onClick={onClose}
          alt="alt"
          className="authorize-main-left-alert-icon"
          src="/assests/img/cancel3.svg"
        />
      )}
    </div>
  );
};

export const ReloadPageButton = ({ onClick, title, ...props }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      window.location.reload();
    }
  };

  return (
    <button {...props} className="manaskopas-main-sub5-btn5 focus-red" type="button" onClick={handleClick}>
      <p className="manaskopas-main-sub5-font">{title ?? "Pārlādēt lapu"}</p>
    </button>
  );
};
