import  { useEffect } from "react";
import { auth } from "../classes/auth";
import { usr } from "../classes/user";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { StateContext } from "../context";

const Logout = () => {
    const navigate = useNavigate();
    const { checkUpdatedSiteRules } = useContext(StateContext);

    useEffect(() => {
        auth.logout();
        usr.empty();
        checkUpdatedSiteRules();
        navigate('/');
    });

  return <></>;
};

export default Logout;
