import { useState } from "react";

const Tooltip = ({ text, children, customStyle, customClass = "", showFocusOutline=false, onClickKeepOpen = false, cannotTabTo=false, onKeyDownFunct=undefined}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [forceOpen, setForceOpen] = useState(false);

  const handleMouseEnter = () => {
    if (onClickKeepOpen && forceOpen) return;
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    if (onClickKeepOpen && forceOpen) return;
    setIsTooltipVisible(false);
  };

  return (
    <div
      className={`tooltip-container ${showFocusOutline ? "focus-red" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setForceOpen((prev) => !prev)}
      tabIndex={cannotTabTo ? -1 : 0}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
      onKeyDown={onKeyDownFunct}
    >
      {children}
      {isTooltipVisible && (
        <div style={{ zIndex: "999", ...customStyle }} className={`tooltip ${customClass}`}>
          <span className="tooltip-container-text">{text}</span>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
