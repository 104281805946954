import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getCms } from "../../classes/cmsApi";
import { Banner, CatalogSearch } from "../../common";
import { AccordItem } from "./components";
import { useTranslation } from "react-i18next";
import { StateContext } from "../../context";
import { useParams, useSearchParams } from "react-router-dom";
import { usr as user } from "../../classes/user";
import useSuperDuperReliableSmoothScroll from "../../hooks/useSuperDuperReliableSmoothScroll";
import { useQuery } from "@tanstack/react-query";
import { ErrorMessage, ReloadPageButton } from "../manaskopas/components/errormessage";
import Loader from "../../common/loader";
import { removeLatvianDiacritics, convertToRGBA } from "../search/search";
import { colors } from "../../helpers/contrast";

export const highlightKeywordHelp = (words, keyword, foregroundColor, backgroundColor) => {
  if (!keyword) return words;

  const cleanKeyword = removeLatvianDiacritics(keyword).toLowerCase();
  const cleanWords = removeLatvianDiacritics(words).toLowerCase();

  let highlightedHTML = "";
  let i = 0;
  let insideTag = false; // Track if we're inside an HTML tag

  while (i < words.length) {
    if (words[i] === "<") {
      // Skip HTML tags
      insideTag = true;
      highlightedHTML += words[i];
    } else if (words[i] === ">") {
      insideTag = false;
      highlightedHTML += words[i];
    } else if (!insideTag) {
      const originalSubstr = words.slice(i, i + keyword.length);
      const cleanSubstr = cleanWords.slice(i, i + keyword.length);
      if (cleanSubstr === cleanKeyword) {
        highlightedHTML += `<span style="background-color:${convertToRGBA(
          foregroundColor,
          0.7
        )}; color:rgba(${backgroundColor}, 0.4);">${originalSubstr}</span>`;
        i += keyword.length;
        continue;
      } else {
        highlightedHTML += words[i];
      }
    } else {
      highlightedHTML += words[i];
    }
    i++;
  }

  return highlightedHTML;
};

const Accordination = ({ helpPages, tabOpened, helpOpened, setHelpOpened, setTabOpened }) => {
  return helpPages.map((item) => (
    <AccordItem
      key={item.id}
      item={item}
      tabOpened={tabOpened}
      helpOpened={helpOpened}
      setHelpOpened={setHelpOpened}
      onMenuItemClick={() => {
        // main accordion title click
        setTabOpened(tabOpened === item.id ? 0 : item.id);
      }}
    />
  ));
};

const Help = () => {
  const { nid } = useParams();
  const { t } = useTranslation();
  const [tabOpened, setTabOpened] = useState(0);
  const [helpOpened, setHelpOpened] = useState(Number(nid) || 0);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedBody, setSelectedBody] = useState("");

  const [helpPagesMap, setHelpPagesMap] = useState({});

  const { language, contrast } = useContext(StateContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const dashboardBanner = {
    path2: t("menuProfileFeatures"),
    path2Url: "/dashboard",
  };
  const bannerData = {
    title: t("help"),
    path1: t("workspaceStart"),
    path1Url: "/",
    ...(user.sub ? dashboardBanner : {}),
    path3: t("help"),
  };

  const handleSearchWordChange = useCallback(
    async (searchWord) => {
      setSearchParams({ searchWord });
    },
    [setSearchParams]
  );

  const { foregroundColor, backgroundColor } = colors[contrast] || colors["contrast_blue"];

  /* this function returns subPage (subcategory in drupal terms) that either:
      matches searchParams.get("searchWord") 
      or is mentioned in the body of the help page.

    function expects helpPagesMap to be already populated
    
    function always returns first found subPage that matches the search word
  */
  const findSubPageFromSearchWord = useCallback(() => {
    if (!searchParams.get("searchWord")) return "";
    if (!helpPagesMap) return "";

    const subPagesWithKeywordInTitle = Object.keys(helpPagesMap).reduce((acc, category) => {
      helpPagesMap[category].forEach((item) => {
        const matchingSubcategories = item.title?.filter((subItem) =>
          removeLatvianDiacritics(subItem.value.toLowerCase()).includes(
            removeLatvianDiacritics(searchParams.get("searchWord").toLowerCase())
          )
        );

        if (matchingSubcategories && matchingSubcategories.length > 0) {
          acc.push(...matchingSubcategories.map((sub) => sub.value));
        }
      });

      return acc;
    }, []);

    if (subPagesWithKeywordInTitle.length > 0) {
      return subPagesWithKeywordInTitle[0];
    }

    const stripHtmlTags = (str) => {
      return str.replace(/<[^>]*>/g, "");
    };

    const subPagesWithKeywordInBody = Object.keys(helpPagesMap).reduce((acc, category) => {
      helpPagesMap[category].forEach((item) => {
        const processedText = stripHtmlTags(item.body?.[0]?.processed || "");
        if (processedText.toLowerCase().includes(searchParams.get("searchWord").toLowerCase())) {
          const matchingSubcategories = item.title?.map((subItem) => subItem.value);
          if (matchingSubcategories && matchingSubcategories.length > 0) {
            acc.push(...matchingSubcategories);
          }
        }
      });
      return acc;
    }, []);

    return subPagesWithKeywordInBody.length > 0 ? subPagesWithKeywordInBody[0] : "";
  }, [helpPagesMap, searchParams]);

  const switchPage = useCallback(
    (nid) => {
      helpPagesMap &&
        Object.keys(helpPagesMap).forEach((category, index) => {
          helpPagesMap[category].forEach((item) => {
            if (helpOpened === 0 && item?.field_noklusejuma_lapa[0]?.value === true && !nid) {
              setHelpOpened(item.nid[0]?.value);
              setSelectedTitle(item.title[0]?.value);
              setSelectedBody(item.body[0]?.processed);
              setTabOpened(index + 1);
              return;
            }

            // check nid
            if (item.nid[0]?.value === Number(nid)) {
              setSelectedTitle(item.title[0]?.value);
              setSelectedBody(item.body[0]?.processed);
            }
            // check keyword in title
            const subPage = findSubPageFromSearchWord();

            const category = item.field_pamatkategorijas_nosaukums[0].value;
            const title = item.title[0].value;

            if (category === t(searchParams.get("searchWord")) || title === t(subPage)) {
              setHelpOpened(item.nid[0]?.value);
            }
            if (item.nid[0]?.value === helpOpened || item.nid[0]?.value === Number(nid)) {
              setSelectedTitle(item.title[0]?.value);
              setSelectedBody(item.body[0]?.processed);
              const index = Object.keys(helpPagesMap).indexOf(category);
              setTabOpened(index > -1 ? index + 1 : Object.keys(helpPagesMap).length + 1);
            }
            return;
          });
        });
    },
    [helpPagesMap, helpOpened, findSubPageFromSearchWord, searchParams, t]
  );

  useEffect(() => {
    switchPage(nid);
  }, [nid, switchPage, searchParams]);

  const fetchData = useCallback(async () => {
    try {
      const response = await getCms("public/help-pages", language);
      const mapping = {};
      for (const item of response) {
        const category = item.field_pamatkategorijas_nosaukums[0].value;

        if (!mapping[category]) {
          mapping[category] = [];
        }
        mapping[category].push(item);
      }
      setHelpPagesMap(mapping);

      const accordData = Object.keys(mapping).map((category, index) => {
        const dataMapping = {};
        let reorder = false;
        mapping[category].forEach((item) => {
          const title = item.field_palidzibas_lapas_nosaukums[0]?.value;
          if (!dataMapping[title]) {
            dataMapping[title] = {
              title,
              sub: [],
            };
          }
          if (item?.field_noklusejuma_lapa[0]?.value === true) {
            dataMapping[title].default = true;
            reorder = true;
          }
          if (item.field_apakskategorijas_nosaukums?.length > 0) {
            item.field_apakskategorijas_nosaukums.forEach((sub) => {
              dataMapping[title].sub.push({
                title: sub.value,
                pageTitle: item.title[0]?.value,
                pageBody: item.body[0]?.processed,
                nid: item.nid[0]?.value,
              });
            });
          } else {
            dataMapping[title].pageTitle = item.title[0]?.value;
            dataMapping[title].pageBody = item.body[0]?.processed;
            dataMapping[title].nid = item.nid[0]?.value;
          }
        });

        const data = Object.values(dataMapping);

        if (reorder) {
          const defaultPage = data.find((item) => item.default);
          data.splice(data.indexOf(defaultPage), 1);
          data.unshift(defaultPage);
        }

        return {
          id: index + 1,
          title: category,
          data,
        };
      });
      return accordData;
    } catch (error) {
      console.error(error);
    }
  }, [language]);

  const {
    data: helpPages,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: ["help-pages", language], // only fetch data if language changes
    queryFn: fetchData,
    placeholderData: [],
    refetchOnWindowFocus: false,
  });

  const dependencyArray = useMemo(() => [nid, helpOpened], [nid, helpOpened]);

  useSuperDuperReliableSmoothScroll(dependencyArray, "palidzibas-content");
  if (isError)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <ErrorMessage text={error.error ?? t("dataFailedToLoad")} />
        <ReloadPageButton />
      </div>
    );

  return (
    <>
      <Banner bannerData={bannerData} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="palidzibas-main">
          <div>
            <div className="palidzibas-main-accordion">
              <Accordination
                helpPages={helpPages}
                tabOpened={tabOpened}
                helpOpened={helpOpened}
                setHelpOpened={setHelpOpened}
                setTabOpened={setTabOpened}
              />
            </div>
          </div>
          <div>
            <div className="help-search-wrapper">
              <CatalogSearch
                placeholder={t("helpSpecifySearchContent")}
                keywords
                manualInput={searchParams.get("searchWord")}
                setManualInput={(val) => {
                  handleSearchWordChange(val);
                }}
              />
            </div>
            {isFetching ? (
              <Loader />
            ) : (
              <div className="palidzibas-main-right" id="palidzibas-content">
                <div className="palidzibas-main-right-width">
                  <span className="palidzibas-main-right-width-font1">
                    {/* {selectedTitle} */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: highlightKeywordHelp(
                          selectedTitle,
                          searchParams.get("searchWord"),
                          foregroundColor,
                          backgroundColor
                        ),
                      }}
                    />
                  </span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: highlightKeywordHelp(
                        selectedBody,
                        searchParams.get("searchWord"),
                        foregroundColor,
                        backgroundColor
                      ),
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Help;
