export const colors = {
    contrast_blue: {
      foregroundColor: "yellow",
      backgroundColor: "black",
    },
    contrast_yellow: {
      foregroundColor: "yellow",
      backgroundColor: "black",
    },
    contrast_blackwhite: {
      foregroundColor: "white",
      backgroundColor: "black",
    },
    contrast_blackyellow: {
      foregroundColor: "black",
      backgroundColor: "yellow",
    },
  };
  