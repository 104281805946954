import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckBox from "../../../common/checkbox";
import { useQuery } from "@tanstack/react-query";
import { getCms } from "../../../classes/cmsApi";
import Loader from "../../../common/loader";
import { StateContext } from "../../../context";

const getTOS = async (language) => {
  try {
    const response = await getCms("api/v1/get/nppTOS", language);
    return response.description;
  } catch (error) {
    console.error(error);
    return "<p>Something went wrong</p>";
  }
};

export const TermsOfUse = ({ setAccepted, className }) => {
  const [checked, setChecked] = useState(false);
  const [isConsentRead, setIsConsentRead] = useState(false);
  const { language } = useContext(StateContext);
  const consentRef = useRef(null);
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["tos", language],
    queryFn: () => getTOS(language),
    refetchOnWindowFocus: false,
  });

  const hasReadRules = useCallback(() => {
    if (!consentRef.current) return;
    const allowedPxNotScrolled = 50;
    const { scrollTop, scrollHeight, clientHeight } = consentRef.current;
    if (Math.floor(scrollTop + clientHeight) >= (scrollHeight - allowedPxNotScrolled)) setIsConsentRead(true);
  }, []);

  useEffect(() => {
    if (isLoading || isError || !data || !consentRef.current) return;
    // handles case where consent is not scrollable
    const dataIsOverflowing = consentRef.current.scrollHeight > consentRef.current.clientHeight;
    if (!dataIsOverflowing) setIsConsentRead(true);
  }, [isLoading, isError, data]);

  useEffect(() => {
    setAccepted(checked && isConsentRead);
  }, [checked, isConsentRead, setAccepted]);

  if (isLoading) {
    return (
      <div className={className}>
        <Loader />;
      </div>
    );
  } 

  return (
    <>
      <div className={className} ref={consentRef} onScroll={hasReadRules}>
        <div dangerouslySetInnerHTML={{ __html: data || "" }} />
      </div>
      <CheckBox
        disabled={!isConsentRead && !isError}
        checked={checked}
        onClick={() => setChecked(!checked)}
        text={t("RegistrationIAmFamiliar")}
      />
    </>
  );
};
