//todo: delete file
import { postCms } from "../../classes/cmsApi";

export const addConfirmation = async (file, status) => {
  const formData = new FormData();
  formData.append("text", file?.name);
  formData.append("file", file);
  formData.append("status", status ? 1 : 0);

  return await postCms("api/v1/add/confirmation/term", formData);
};

export const updateConfirmation = async (currentFile, file, status) => {
  const formData = new FormData();
  formData.append("id", currentFile?.term_id);
  formData.append("text", file?.name ?? currentFile?.term_title);
  formData.append("file", file);
  formData.append("status", status ? 1 : 0);

  return await postCms("api/update/confirmation/term", formData);
};
