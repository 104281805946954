import { useContext } from "react";
import "./index.css";
import { Dropdown, Input } from "../pages/manaskopas/components";
import PrimaryButton from "../common/atoms/primarybutton";
import ModalComponent from "./modal";
import { StateContext } from "../context";
import { useForm } from "react-hook-form";
import { BACKEND_URL, post } from "../classes/api";
import { Trans, useTranslation } from "react-i18next";
import { countries } from "../countries";
import { t } from "i18next";
import { auth } from "../classes/auth";
import { usr as user, usr } from "../classes/user";

const RenderFields = ({ type, register, getFieldState, triggerValidation, getValues, setValue, isDirty, errors }) => {
  const { t } = useTranslation();

  switch (type) {
    case "DD_Contact":
      return (
        <>
          <h2 className="column-h2">
            <Trans i18nKey="editInformationAboutContactPerson" />
          </h2>
          <Input
            name="givenName"
            getFieldState={getFieldState}
            register={register}
            getValues={getValues}
            placeholder={t("ContactpersonFirstName")}
            rules={{
              required: { message: "fieldIsRequired", value: true },
            }}
            errors={errors}
          />
          <Input
            name="familyName"
            getFieldState={getFieldState}
            register={register}
            getValues={getValues}
            placeholder={t("registrationJuridicalLastName")}
            rules={{
              required: { message: "fieldIsRequired", value: true },
            }}
            errors={errors}
          />
          <Input
            isMail
            name="email"
            getFieldState={getFieldState}
            register={register}
            getValues={getValues}
            placeholder={t("email")}
            rules={{
              required: { message: "fieldIsRequired", value: true },
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                message: "invalidEmail",
              },
            }}
            errors={errors}
          />
          <Input
            name="phoneNr"
            getFieldState={getFieldState}
            register={register}
            getValues={getValues}
            placeholder={t("phone")}
            rules={{
              required: { message: "fieldIsRequired", value: true },
              pattern: { value: /^(\+371 ?)?[0-9]{8}$/, message: "invalidPhone" },
            }}
            errors={errors}
          />
          <div
            style={{
              marginTop: 30,
            }}
          >
            <PrimaryButton
              title={t("datasetAddSave")}
              disabled={!isDirty || Object.keys(errors).length}
              hideSvg
              style={{
                height: 64,
                width: 134,
                textAlign: "center",
                justifyContent: "center",
                padding: 0,
              }}
            />
          </div>
        </>
      );

    case "DD_Juridical":
      return (
        <>
          <h2 className="column-h2">
            <Trans i18nKey="editInformationAboutTheOrganisation" />
          </h2>
          <Dropdown
            style={{ marginTop: 20 }}
            fieldName="country"
            placeholder={t("dropdownPick")}
            defval={usr?.country || "LV"}
            register={register}
            errors={errors}
            rules={{
              required: { message: "fieldIsRequired", value: true },
            }}
            options={countries.map((country) => ({ key: country.value, value: country.label }))}
            setValue={setValue}
            getValues={getValues}
            getFieldState={getFieldState}
          />
          <Input
            name="juridicalPersonAddress"
            getFieldState={getFieldState}
            register={register}
            getValues={getValues}
            rules={{
              required: { message: "fieldIsRequired", value: true },
            }}
            placeholder={t("registrationJuridicalAddress")}
            errors={errors}
          />
          <Input
            isMail
            name="secondEmail"
            getFieldState={getFieldState}
            register={register}
            getValues={getValues}
            placeholder={t("email")}
            rules={{
              required: { message: "fieldIsRequired", value: true },
              pattern: { value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: "invalidEmail" },
            }}
            errors={errors}
          />
          <Input
            name="website"
            rules={{
              required: { message: "fieldIsRequired", value: true },
              pattern: {
                value: /^((ftp|http|https):\/\/)?www\.([A-Za-z0-9-]+)\.([A-z]{2,})/,
                message: "invalidWebpage",
              },
            }}
            placeholder={t("registrationJuridicalWebpage")}
            tooltipText="www.sia.lv"
            register={register}
            errors={errors}
            getValues={getValues}
            getFieldState={getFieldState}
          />
          <div
            style={{
              marginTop: 30,
            }}
          >
            <PrimaryButton
              title={t("datasetAddSave")}
              disabled={!isDirty || Object.keys(errors).length}
              hideSvg
              style={{
                height: 64,
                width: 134,
                textAlign: "center",
                justifyContent: "center",
                padding: 0,
              }}
            />
          </div>
        </>
      );

    case "DN":
      return (
        <>
          <Input
            title={t("profileName")}
            name="profileName"
            getFieldState={getFieldState}
            register={register}
            getValues={getValues}
            placeholder={t("profileName")}
            errors={errors}
          />
          <Input
            isMail
            title={t("emailAddress")}
            name="email"
            getFieldState={getFieldState}
            register={register}
            getValues={getValues}
            placeholder={`${t("emailAddress")}`}
            rules={{ required: { message: "fieldIsRequired", value: true }, pattern: {value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: "invalidEmail"}}}
            errors={errors}
          />
          <div style={{ marginTop: 30 }}>
            <PrimaryButton
              title={t("datasetAddSave")}
              disabled={!isDirty || Object.keys(errors).length}
              hideSvg
              style={{
                height: 64,
                width: 134,
                textAlign: "center",
                justifyContent: "center",
                padding: 0,
              }}
            />
          </div>
        </>
      );
    default:
      return null;
  }
};

const EditProfile = ({ trigger, type }) => {
  const { handleModalClose } = useContext(StateContext);
  const { t } = useTranslation();
  const handleClose = () => {
    reset({ ...user });
    handleModalClose(type);
  };

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
    getFieldState,
    trigger: triggerValidation,
    // control
  } = useForm({
    mode: `all`,
    defaultValues: { ...user }, // isDirty fix
  });

  const updateData = async (body) => {
    try {
      const resp = await post(`${BACKEND_URL}/api/updateProfile`, JSON.stringify(body));
      const { ok } = resp;
      if (ok) {
        await auth.refetchTokens();
        await user.refresh();
        handleClose();
      } else {
        console.error(`Neizdevās saglabāt lietotāja datus`);
      }
    } catch (err) {
      if (err.name === "SyntaxError") console.error("Unexpected api call return");
      else console.error(err);
    }
  };
  return (
    <ModalComponent
      className={"edit-profile"}
      id={type}
      trigger={trigger}
      small
      title={t("popupMessageModifyProfile")}
      onClose={handleClose}
    >
      <div className="edit-profile-modal-content">
        <form onSubmit={handleSubmit(updateData)}>
          <RenderFields
            type={type}
            register={register}
            getFieldState={getFieldState}
            getValues={getValues}
            trigger={triggerValidation}
            setValue={setValue}
            isDirty={isDirty}
            errors={errors}
          />
          {/* <DevTool control={control} /> */}
        </form>
      </div>
    </ModalComponent>
  );
};

export default EditProfile;
