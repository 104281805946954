export function extractDrupalData(data) {
  const extractedData = data.map((item, index) => {
    const extractedItem = {};
    for (const key in item) {
      if (key === "field_content_category_detailed") {
        continue;
      }
      if (item.hasOwnProperty(key)) {
        if (
          key === "field_publisher_declaration" ||
          key === "field_data_format_schema" ||
          key === "field_content_category"
        ) {
          if (key === "field_content_category") {
            data[index][key].forEach((category, i) => {
              if (i === 0) {
                extractedItem[key] = category.target_id || null;
              } else {
                if (Array.isArray(extractedItem["field_content_category_detailed"])) {
                  extractedItem["field_content_category_detailed"].push(category.target_id);
                } else {
                  extractedItem["field_content_category_detailed"] = [category.target_id];
                }
              }
            });
          } else {
            extractedItem[key] = data[index][key]?.[0]?.target_id || null;
          }
        } else if (key === "field_dataset_tags") {
          extractedItem[key] = Array.isArray(item[key]) ? item[key].map((item) => item.value) : null;
        } else if (key === "field_geographic_coverage_nuts") {
          extractedItem[key] = Array.isArray(item[key]) ? item[key].map((item) => item.target_id) : null;
        } else if (key === "field_format_model") {
          extractedItem[key] = item[key]?.[0]?.target_id || null;
        } else if (key === "field_download_url") {
          extractedItem[key] = item[key]; // Return the value as it is
        } else if (key === "child_taxonomy_terms") {
          extractedItem[key] = Array.isArray(item[key])
            ? item[key].map((term) => ({ tid: term.tid, name: term.name }))
            : null;
        } else if (key === "parent_taxonomy_terms") {
          extractedItem[key] = Array.isArray(item[key])
            ? item[key].map((term) => ({ tid: term.tid, name: term.name }))
            : null;
        } else if (key === "field_field_data_sample") {
          extractedItem[key] = Array.isArray(item[key])
            ? item[key].map((sample) => ({
                target_id: sample.target_id,
                display: sample.display,
                description: sample.description,
                name: sample.name,
              }))
            : null;
        } else if (key === "field_data_sample_datetime") {
          extractedItem[key] = item[key]?.[0]?.value || null;
        } else if (key === "field_field_data_format_syntax") {
          extractedItem[key] = Array.isArray(item[key]) ? item[key].map((item) => item.value) : null;
        } else if (key === "field_language_content") {
          extractedItem[key] = Array.isArray(item[key]) ? item[key].map((item) => item.value) : null;
        } else {
          const values = Array.isArray(item[key]) ? item[key].map((item) => item.value) : null;
          extractedItem[key] =
            values && values.length > 1 ? values : values && values.length === 1 ? values[0] : null;
        }
      }
    }
    return extractedItem;
  });

  const finalData = extractedData.map((item) => {
    const formattedItem = {};
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        const formattedKey = key.startsWith("field_") ? key.replace(/^field_/, "") : key;
        formattedItem[formattedKey] = item[key];
      }
    }
    return formattedItem;
  });

  return finalData;
}
