import { DataBox } from ".";
import React from "react";
import Field from "../../../common/atoms/textfield";
import moment from "moment";
import { useTranslation } from "react-i18next";

const InfoContainer = ({ isNew, defval }) => {
  const { t } = useTranslation();
  const { distribution_mode } = defval;
  const children = [
    <>
      <Field title={t("datasetAddPublicationUID")} text={defval?.distribution_uid ?? "-"} />
      <Field title={"Datu kopdas UID: "} text={defval?.nid ?? "-"} />
      <Field title={t("datasetAddPublicationStatus")} text={defval?.data_set_statuss ?? "Jauna datu kopa"} />

      <Field title={"Datu kopas redzamība:  "} text={defval?.dataset_visibility ?? "-"} />
      <Field title={t("datasetAddPublicationMode")} text={defval?.data_set_mode ?? "-"} />
    </>,
    <>
      <Field title={t("datasetAddPublishedFrom")} text={defval?.published_from ?? "-"} />
      <Field title={t("datasetAddNextUpdate")} text={defval?.next_update ?? "-"} />
      <Field
        title={t("dataSetUpdated") + ":"}
        text={defval?.changed ? moment.unix(defval?.changed).format("DD-MM-YYYY") : "-"}
      />
      {distribution_mode === "file" ? (
        <>
          <Field title={t("datasetAddAPIKey")} text={defval?.publishing_api_key} />
          <Field title={"Datu kopas padošanas saite: "} text={`/api/pull/metadata/${defval?.nid}`} />
        </>
      ) : (
        <>
          <Field title={t("datasetMQTTTopic")} text={defval?.source_mqtt_topic} />
          <Field title={t("datasetAddAPIKey")} text={defval?.publishing_api_key} />
          {defval?.data_set_mode === "sandbox" && (
            <Field title={t("datasetAddSnapshotMQTTUrl")} text={defval?.sandbox_mqtt_topic} />
          )}
        </>
      )}
      <Field title={"Metadatu atjaunināšanas saite: "} text={"/client/update/metadata"} />
    </>,
  ];

  return <DataBox children={children} />;
};

export default InfoContainer;
